import { object, number, string, boolean } from 'yup'
import NewPrescriptionSchema from './NewPrescription'

export const EmptyRxRequestSchema = object().shape()

export const ChangeRxRequestSchema = object().shape({
  selectedIndex: number().min(0, 'Please, select any medication'),
})

export const PriorChangeRxRequestSchema = object().shape({
  priorAuthNumber: string()
    .max(35, 'Maximum prior number length is 35 characters')
    .required('Please, fill Prior authorization number'),
})

export const prescriberChangeRxRequestSchema = (label) =>
  object().shape({
    prescriberAuthorizationData: string().max(32, 'Maximum length is 32 characters').required(`Please ${label}`),
  })

export const prescriberChangeStateLicenseRxRequestSchema = (label) =>
  object()
    .shape({
      prescriberLicenseInactive: boolean().required(),
    })
    .concat(prescriberChangeRxRequestSchema(label))

export const ChangeRxRequestQuerySchema = object().shape({
  rxChangeRequestId: number().required(),
  providerId: string().required(),
  prescriptionExternalId: number().required(),
  newPrescription: NewPrescriptionSchema.notRequired().nullable(),
  newPrescriptionPatientId: number().required(),
  selectedMedicationId: number(),
  priorAuthNumber: string(),
  prescriberAuthorizationData: string(),
  prescriberLicenseInactive: boolean(),
})
