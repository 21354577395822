import { InferType, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  visitId: string().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = {
  data: string
}

export const url = ({ visitId, providerId }) => `/v1/visits/${visitId}/phone/${providerId}/token`
