import { InferType, object, string } from 'yup'
import { Schedule } from 'types/Provider/Schedule'
import { TimeSlotPendingSchema } from 'schemas/TimeSlot'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  schedule: object({
    date: string().required(),
    slot: TimeSlotPendingSchema.required(),
  }).required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = Schedule

export const url = ({ providerId }: Request) => `/v2/providers/${providerId}/schedules`

export const getBody = ({ schedule }) => ({ schedule })
