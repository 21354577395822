export const PRONOUNS = [
  'She/Her',
  'He/Him',
  'They/Them',
  'She/Them',
  'He/Them',
  'They/Her',
  'They/Him',
  'I do not wish to disclose',
]

export const pronounOptions = PRONOUNS.map((value) => ({
  value,
  label: value,
}))

export const RACE_ETHNICITY = [
  'Asian or Asian American',
  'Biracial or Multiracial',
  'Black or African American',
  'Hispanic or Latino/a/x',
  'Middle Eastern or North African (MENA) or Arab Origin',
  'Native American or Alaska Native',
  'Native Hawaiian or Other Pacific Islander Native',
  'White',
  'Two or More Races',
  'I do not wish to disclose',
]

export const raceEthnicityOptions = RACE_ETHNICITY.map((value) => ({
  value,
  label: value,
}))

export const GENDER_IDENTITY = [
  'Agender',
  'Female',
  'Male',
  'Gender-Fluid',
  'Non-Binary',
  'TGNC (Transgender, Gender Non-Conforming)',
  'I do not wish to disclose',
]

export const genderIdentityOptions = GENDER_IDENTITY.map((value) => ({
  value,
  label: value,
}))

export const LICENSES = [
  'ALC',
  'APC',
  'APRN',
  'Associate/Provisional License',
  'BA',
  'BS',
  'CAP',
  'CAPA',
  'CAPSW',
  'CCN',
  'CHES/MCHES',
  'CISW',
  'CMSW',
  'CNC',
  'CNS',
  'CPC',
  'CSW',
  'CSW-PIP',
  'DO',
  'DSW',
  'FNP',
  'ILMFT',
  'ISW',
  'LAC',
  'LAMFT',
  'LAPC',
  'LAPSW',
  'LASAC',
  'LAT',
  'LCMFT',
  'LCMHC',
  'LCMHC-S',
  'LCMHC-A',
  'LCPC',
  'LCSW',
  'LCSW-A',
  'LCSW-C',
  'LCSW-R',
  'LCSW-S',
  'LEP',
  'LICSW',
  'LIMHP',
  'LISAC',
  'LISW',
  'LISW-AP',
  'LISW-CP',
  'LISW-S',
  'LMFT',
  'LMHC',
  'LMHP',
  'LMSW',
  'LMSW-ACP',
  'LMSW-C',
  'LMSW-M',
  'LPC',
  'LPC-A',
  'LPC-MH',
  'LPC-MHSP',
  'LPC-S',
  'LPCC',
  'LPCC-S',
  'LSAT',
  'LSCSW',
  'LSW',
  'M.Ed',
  'MA',
  'MD',
  'MFTA',
  'MPH',
  'MS',
  'MSW',
  'NBC-HWC',
  'NP',
  'Other',
  'PhD',
  'PMHNP',
  'Psychologist - Master',
  'PsyD',
  'RD',
  'RDN',
  'LMFTA',
  'PA',
]

export const licensesOptions = LICENSES.map((value) => ({
  value,
  label: value,
}))

export const DIAGNOSTIC_EXPERTISE = [
  'ADD/ADHD',
  'Autism Spectrum Disorder',
  'Bipolar Disorder (I and/or II)',
  'Major Depressive Disorder (MDD)',
  'Persistent Depressive Disorder (Dysthymia)',
  'Postpartum Depression',
  'Generalized Anxiety Disorder (GAD)',
  'Panic Disorder',
  'Social Anxiety Disorder',
  'Obsessive-Compulsive Disorder (OCD)',
  'Body Dysmorphic Disorder',
  'Anorexia Nervosa',
  'Binge-Eating Disorder',
  'Bulimia Nervosa',
  'Post-Traumatic Stress Disorder (PTSD)',
  'Adjustment Disorder',
  'Hypersomnolence Disorder',
  'Insomnia Disorder',
  'Gender Dysphoria',
  'Disruptive, Impulse Control, and Conduct Disorders',
  'Oppositional Defiant Disorder (ODD)',
  'Alcohol Use Disorder (AUD)',
  'Opioid Use Disorder (OUD)',
  'Other Substance Use Disorder (SUD)',
  'Behavior Addiction - Gambling Disorder',
  'Behavior Addiction - Internet Gaming Disorder',
  'Schizophrenia Spectrum & Other Psychotic Disorders',
  'Borderline Personality Disorder',
  'Narcissistic Personality Disorder',
  'Obsessive-Compulsive Personality Disorder',
  'Grief/Loss',
  'Self-Injury',
  'Suicidality',
  'None',
]

export const diagnosticExpertiseOptions = DIAGNOSTIC_EXPERTISE.map((value) => ({
  value,
  label: value,
}))

export const COMMUNITY_EXPERTISE = [
  'Asexual',
  'Athletes',
  'BIPOC (Black, Indigenous, People of Color)',
  'Caretakers',
  'Chronic Illness',
  'DACA/Undocumented',
  'First Generation College Students',
  'Foster Care (including those aging out of the foster care system)',
  'Graduate and Professional School Students',
  'History of Incarceration',
  'Immigrants',
  'In Recovery (for any addictions)',
  'International Students',
  'Learning Differences',
  'LGBQ (Lesbian, Gay, Bisexual, Queer)',
  'Military (Active Duty, Reserves, Veteran, Partner)',
  'Native Americans',
  'Non-Traditional Students',
  'Pansexual',
  'Parents',
  'Pell Grant Eligible',
  'Physical or Cognitive Disabilities',
  'Pregnancy/Postpartum',
  'Relationships/Family/Couples',
  'Religion/Spiritual',
  'Reproductive-Related Mental Health Issues in Women',
  'Substance Users and/or those impacted by the substance use of a loved one',
  'Survivors of Abuse',
  'TGNC (Transgender, Gender Non-Conforming)',
  'Transfer Students',
  'None',
]

export const communityExpertiseOptions = COMMUNITY_EXPERTISE.map((value) => ({
  value,
  label: value,
}))

export const RELIGIOUS_EXPERTISE = [
  'Agnostic',
  'Atheist',
  'Buddhist',
  'Catholic',
  'Christian',
  'Evangelical',
  'Hindu',
  'Jainism',
  "Jehovah's Witness",
  'Jewish',
  'Mormon',
  'Muslim',
  'Orthodox Christian',
  'Protestant (Christian)',
  'Sikhism',
  'Spiritual',
  'None',
]

export const religiousExpertiseOptions = RELIGIOUS_EXPERTISE.map((value) => ({
  value,
  label: value,
}))

export const LANGUAGES = [
  'American Sign Language (ASL)',
  'Arabic',
  'Armenian',
  'Bengali',
  'Cantonese',
  'English',
  'Farsi',
  'French',
  'German',
  'Gujarati',
  'Haitian Creole',
  'Hebrew',
  'Hindi',
  'Italian',
  'Japanese',
  'Korean',
  'Mandarin',
  'Nepali',
  'Pashto',
  'Portuguese',
  'Punjabi',
  'Russian',
  'Spanish',
  'Swedish',
  'Tibetan',
  'Turkish',
  'Vietnamese',
]

export const languagesOptions = LANGUAGES.map((value) => ({
  value,
  label: value,
}))
