import { object, string, boolean } from 'yup'
import stubTrue from 'lodash/stubTrue'

import { stateValues } from 'constants/states'

const AddressSchema = object().shape({
  street1: string()
    .label('Street 1')
    .trim('Street 1 should have no leading or trailing spaces')
    .min(2, 'Street 1 too Short')
    .max(35, 'Street 1 too Long')
    .required(),
  street2: string()
    .label('Street 2')
    .trim('Street 2 should have no leading or trailing spaces')
    .max(35, 'Street 2 too Long')
    .nullable()
    .notRequired(),
  city: string()
    .label('City')
    .trim('City should have no leading or trailing spaces')
    .min(2, 'City is too Short')
    .max(35, 'City is too Long')
    .required(),
  zip: string()
    .label('Zip Code')
    .matches(/\d{5}/, 'Zip Code must be 5 digits')
    .min(5, 'Zip Code is too Short')
    .max(5, 'Zip Code is too Long')
    .required(),
  state: string().label('State').min(2, 'State is too Short').max(2, 'State is too Long').required(),
})

export default AddressSchema

export const getAddressSchema = (hasAddressType) => {
  const schema = {
    id: string(),
    street1: string().label('Street 1').required(),
    street2: string().label('Street 2').nullable().notRequired(),
    city: string().label('City').required(),
    state: string().label('State').oneOf(stateValues, 'Must be a valid state').required(),
    zip: string()
      .label('Zipcode')
      .matches(/^(\d{5})$/, 'Must be a 5 digit zipcode')
      .required(),
    preferred: boolean().default(true),
  }
  if (hasAddressType === stubTrue()) {
    schema.addressType = string().label('Address Type').required()
  }
  return object().shape(schema)
}
