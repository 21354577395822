import { object, string } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  filter: object().shape({
    name: string(),
    strength: string(),
  }),
})

export const url = ({ filter }) => `/v1/medications/show?${queryString.stringify({ filter })}`
