/*
  Some backend microservices wrap responses with data: { ... }.
  We always want to work with deepest non-empty data.
*/
const getDeepestData = (response) => {
  if (response) {
    while (response.data) {
      response = response.data
    }
  }
  return response
}

export default getDeepestData
