import { object, string } from 'yup'

export { auth as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  refreshToken: string().required(),
})

export const url = () => '/v1/refresh'

export const getBody = ({ refreshToken }) => ({ refreshToken })
