import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import User from 'singletons/User'

const FlagSyncer = () => {
  const flags = useFlags()
  useEffect(() => {
    User.setFeatureFlags(flags)
  }, [flags])
  return null
}

export default FlagSyncer
