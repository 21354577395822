import { ImageResponse } from 'types/Image'
import { InferType, object, string } from 'yup'
export { member as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  pendingVisitId: string().required(),
})

export type Request = InferType<typeof Schema>
export type SuccessfulResponse = ImageResponse

export const url = ({ memberId, pendingVisitId }) => `/v1/${memberId}/images/pending_visit/${pendingVisitId}`
