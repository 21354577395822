const mergeMobxListsBy = (curMap, curInstancesList, newList, key, callBack, shouldDelete = true) => {
  const idsSet = new Set()
  const newInstancesList = []

  let id
  let curObj
  let shouldPush
  let wasSomethingDeleted = false

  if (newList) {
    for (const newObj of newList) {
      id = newObj[key]
      shouldPush = !idsSet.has(id)
      curObj = curMap.get(id)
      if (curObj) {
        curObj.merge(newObj)
      } else {
        curObj = callBack(newObj)
        curMap.set(id, curObj)
        curInstancesList.push(curObj)
      }
      if (shouldPush) {
        newInstancesList.push(curObj)
        idsSet.add(id)
      }
    }
  }

  if (shouldDelete) {
    for (const [curId, curObj] of curMap) {
      if (!idsSet.has(curId)) {
        wasSomethingDeleted = true
        curObj.destroy()
        curMap.delete(curId)
      }
    }

    if (wasSomethingDeleted) {
      curInstancesList.replace(newInstancesList)
    }
  }
}

export default mergeMobxListsBy
