import { makeAutoObservable, when, runInAction } from 'mobx'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'

import makeQuery from 'api/makeQuery'
import GlobalEvents from 'singletons/GlobalEvents'
import mergeMobxListsBy from 'util/mergeMobxListsBy'
import Rx from './Rx'

class Rxs {
  rxsMap = new Map()
  list = []

  constructor(visit, initialValues) {
    this.visit = visit

    if (initialValues?.length) {
      for (const v of initialValues) {
        const newRx = new Rx(this, v)
        this.list.push(newRx)
        this.rxsMap.set(v.id, newRx)
      }
    }

    makeAutoObservable(this, {
      destroy: false,
      map: false,
    })
  }

  map(cb) {
    return this.list.map(cb)
  }

  replace(arr) {
    mergeMobxListsBy(this.rxsMap, this.list, arr || [], 'id', (v) => new Rx(this, v))
  }

  destroy() {
    this.list.forEach((rx) => rx.destroy())
  }

  get length() {
    return this.list.length
  }

  // Unique pharmacies for all medications
  get uniqueRxsByPharmacy() {
    return uniqBy(this.list, (rx) => rx.pharmacy?.storeName)
  }

  async addMedication({
    name,
    directions,
    ndc,
    unit,
    dispenseUnit,
    refills,
    daysSupply,
    quantity,
    substitutions,
    pharmacyNotes,
    schedule,
    dispensableDrugId = '',
  }) {
    try {
      const { visit } = this

      await when(() => !!visit.memberExtendedData)

      let memberExternalId = visit.memberExtendedData.privateData.member.rxVendorId

      if (!memberExternalId) {
        await visit.dirtyRefetchMemberExtendedData()
        memberExternalId = visit.memberExtendedData.privateData.member.rxVendorId
      }

      if (!memberExternalId) {
        throw new Error('Patient is not created in DoseSpot')
      }

      if (schedule && parseInt(schedule, 10) > 0) {
        throw -1
      } else {
        const rx = omitBy(
          {
            name,
            memberId: visit.memberId,
            providerId: visit.providerId,
            pharmacyId: visit.pharmacy?.externalId,
            substitutions,
            refills,
            daysSupply,
            directions,
            quantity,
            pharmacyNotes,
            unit: unit || '-',
            ndc,
            memberExternalId,
            unitsSourceId: '' + dispenseUnit,
            dispensableDrugId: '' + dispensableDrugId,
          },
          (v) => v === '' || v === undefined,
        )

        if (process.env.NODE_ENV !== 'production') {
          console.log('Final rx:', rx)
        }

        const response = await makeQuery('postRx2', {
          visitId: visit.visitId,
          rx,
        })

        if (visit.status === 'completed') {
          await makeQuery('postFillPrescriptions', {
            visitId: visit.visitId,
          })
        }

        visit.merge(response)
      }
    } catch (e) {
      if (e === -1) {
        GlobalEvents.emit('addMedication', {
          status: 'error',
          name,
        })
      } else throw e
    }
  }

  async removeMedication(rxId, cancellationReason) {
    const idx = this.list.findIndex((rx) => rx.prescriptionId === rxId)
    if (idx !== -1) {
      const rx = this.list[idx]
      await rx.remove(cancellationReason)
      if (rx.storedOnDoseSpot) {
        await rx.refetch()
      } else {
        runInAction(() => {
          rx.destroy()
          this.list.splice(idx, 1)
        })
      }
    }
  }
}

export default Rxs
