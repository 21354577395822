import { object, mixed, string, array, bool, boolean } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  visitId: string().required(),

  followupAt: mixed(),
  followup: bool().required(),
  followupInstructions: string(),
  dischargeInstructions: array().required(),
  restriction: object().shape({
    restrictions: array(),
    resumeDate: string(),
  }),
  medicationReconciliation: object()
    .shape({
      reconciled: boolean(),
      notes: string().nullable(),
    })
    .notRequired()
    .nullable(),
})

export const url = ({ providerId, visitId }) => `/v1/providers/${providerId}/visits/${visitId}/discharge`

export const getBody = ({
  followupAt,
  followup,
  followupInstructions,
  dischargeInstructions,
  restriction,
  medicationReconciliation,
}) => ({
  followupAt,
  followup,
  followupInstructions,
  dischargeInstructions,
  restriction,
  medicationReconciliation: medicationReconciliation || undefined,
})
