import { action, makeObservable } from 'mobx'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'

let uId = 0

const formatPeriod = (period) => +new Date(`01/01/2000 ${period.slice(0, 6)}00`)

class Period {
  id = uId++
  from = ''
  to = ''

  constructor(availabilityCollection, name, period) {
    makeObservable(this, {
      delete: action.bound,
      setFrom: true,
      setTo: true,
      from: true,
      to: true,
      pair: true,
      isValid: true,
    })

    this.from = formatPeriod(period[0])
    this.to = formatPeriod(period[1])
    this.name = name
    this.availabilityCollection = availabilityCollection
  }

  setFrom(value) {
    this.from = value
  }

  setTo(value) {
    this.to = value
  }

  get isValid() {
    return isAfter(this.to, this.from)
  }

  delete() {
    this.availabilityCollection.deletePeriod(this.name, this.id)
  }

  get pair() {
    return [this.from, this.to].map((time) => format(time, 'HH:mm:ss'))
  }
}

export default Period
