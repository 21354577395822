import { object, array, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  dxs: array().required(),
})

export const url = ({ visitId }) => `/v1/visit/${visitId}/dx`

export const getBody = ({ dxs }) => ({ dxs })
