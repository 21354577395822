import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  pharmacyId: string().required(),
  name: string().required(),
})

export const url = ({ visitId }) => `/v1/visits/${visitId}/pharmacy`

export const getBody = ({ pharmacyId, name }) => ({ pharmacyId, name })
