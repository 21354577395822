import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  note: object(),
})

export const url = ({ visitId }) => `/v1/visits/${visitId}/notes`

export const getBody = ({ note }) => ({ note })
