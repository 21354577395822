import { makeObservable } from 'mobx'
import Record from 'models/Record'
import { AssessmentScore } from 'types/Visit/MeasurementBasedCare'

export type MbcScore = {
  createdAt: string
  pendingVisitId: string
  scheduledAt: string
  score: number
  status: string
  visitType: string
}

class Score extends Record {
  static INITIAL_FIELDS = {
    date: '',
    score: 0,
    visitType: '',
  }

  constructor(score: AssessmentScore) {
    super(score)
    makeObservable(this, {})
  }

  get displayValues() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { date: this.date, score: this.score, visitType: this.visitType }
  }
}

export default Score
