import { makeAutoObservable, observable, computed, comparer, runInAction } from 'mobx'

import makeQuery from 'api/makeQuery'
import SchedulingBase from '../visits/SchedulingBase'

class SchedulingsCollection {
  list = []

  get visibleList() {
    return this.list.filter((s) => s.createdBy === 'member' && !s.accepted && !s.cancelled && !s.rejected)
  }

  destroy() {
    for (const scheduling of this.list) {
      scheduling.destroy()
    }
  }

  get length() {
    return this.list.length
  }

  constructor(provider) {
    makeAutoObservable(this, {
      destroy: false,
      list: observable.shallow,
      visibleList: computed({ equals: comparer.shallow }),
    })
    this.provider = provider
  }

  async refetch() {
    const schedulingsList = await makeQuery('getProviderSchedulings', {
      id: this.provider.providerId,
    })
    runInAction(() => {
      this.list.forEach((v) => v.destroy())
      this.list.replace(schedulingsList.map((schedule) => new SchedulingBase(schedule)))
    })
  }

  remove(schedulingId) {
    const idx = this.list.findIndex((scheduling) => scheduling.schedulingId === schedulingId)
    if (idx !== -1) {
      this.list[idx].destroy()
      this.list.splice(idx, 1)
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn('Tried to remove non-existing scheduling')
    }
  }
}

export default SchedulingsCollection
