import { number, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'
import queryString from 'qs'
import { PaginationRequestParams } from 'types/api'
import { ProviderMember, ProviderMembersFilterType } from 'types/ProviderMember'

export const Schema = object({
  providerId: string().required(),
  filter: object().shape({
    keyword: string(),
    dob: string(),
  }),
  sort: object().shape({
    first_name: number(),
    last_name: number(),
    first_name_preferred: number(),
    dob: number(),
  }),
})

export type Request = Omit<PaginationRequestParams<ProviderMembersFilterType>, 'page'> & { providerId: string }

export type SuccessfulResponse = { members: ProviderMember[] }

export const url = ({ providerId, page, sort, filter }) =>
  `/v1/providers/${providerId}/members?${queryString.stringify({ page, sort, filter }, { indices: false })}`
