import { makeObservable, action, runInAction, onBecomeObserved, computed } from 'mobx'
import mergeMobxListsBy from 'util/mergeMobxListsBy'
import VisitsCollection from '../VisitsCollection'
import makeQuery from 'api/makeQuery'
import PendingVisit from '../visits/PendingVisit'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import formatISO from 'date-fns/formatISO'
import { API_PAST_VISITS_FETCH_LIMIT } from 'constants/apiFetchLimits'
import { isEqual } from 'lodash'
import parse from 'date-fns/parse'

const defaultFilters = {
  memberId: '',
  pendingVisitId: '',
  date: null,
}

class PastVisitsCollection extends VisitsCollection {
  constructor(...args) {
    super(...args)
    const { memberId } = args?.[0] || {}

    this.filters = {
      ...defaultFilters,
      memberId,
    }
    this.initialMemberId = memberId
    this.memberVisitTypeFilter = args.length && args[0].memberVisitTypeFilter
    this.page = 0
    this.paginationTotal = 0
    this.loading = false
    this.selectedPendingVisitId = ''
    makeObservable(this, {
      endOfPage: computed,
      loading: true,
      page: true,
      mergePendingVisits: action,
      incrementPage: action.bound,
      toggleLoading: action.bound,
      filters: true,
      setFilters: action.bound,
      clearFilters: action.bound,
    })

    this.disposePastVisitsFetcher = onBecomeObserved(this, 'list', () => {
      if (this.initialMemberId) {
        this.refetch()
      }
    })
  }

  get canIncrementPage() {
    return !this.loading && !this.selectedPendingVisitId && !this.endOfPage
  }

  destroy() {
    this.disposePastVisitsFetcher()
  }

  setFilters(filters) {
    if (!isEqual(filters, this.filters)) {
      this.filters = filters
      this.page = 0
      this.refetch()
    }
  }

  clearFilters() {
    this.filters = { ...defaultFilters }
    this.page = 0
    this.refetch()
  }

  incrementPage() {
    this.page = this.page + API_PAST_VISITS_FETCH_LIMIT
    this.refetch()
  }

  toggleLoading() {
    this.loading = !this.loading
  }

  mergePendingVisits(pendingVisits, shouldDelete) {
    mergeMobxListsBy(this.map, this.list, pendingVisits, 'pendingVisitId', (v) => new PendingVisit(v), shouldDelete)
    this.list.sort((a, b) => new Date(b.scheduledAt) - new Date(a.scheduledAt))
  }

  async refetch() {
    this.toggleLoading()

    const { memberId, date, pendingVisitId } = this.filters

    const filter = {
      status: ['completed'],
      provider_id: this.initialMemberId ? undefined : this.provider.providerId,
      member_id: memberId || undefined,
      _id: pendingVisitId || undefined,
    }

    let params = {
      filter,
      page: {
        skip: this.page,
        limit: API_PAST_VISITS_FETCH_LIMIT,
      },
      sort: {
        scheduled_at: -1,
      },
    }

    if (date) {
      params = {
        ...params,
        gt: {
          scheduled_at: formatISO(startOfDay(parse(date, 'MM-dd-yyyy', new Date()))),
        },
        lt: {
          scheduled_at: formatISO(endOfDay(parse(date, 'MM-dd-yyyy', new Date()))),
        },
      }
    }

    let result

    try {
      result = await makeQuery('getPendingVisits', params)
    } catch (error) {
      result = {
        paginated: [],
        meta: {
          results: 0,
        },
      }
    }

    runInAction(() => {
      this.toggleLoading()
      this.paginationTotal = result.meta?.results || result.paginated?.length || 0
      this.mergePendingVisits(result.paginated, this.page === 0)
    })
  }

  get endOfPage() {
    return this.list.length >= this.paginationTotal
  }
}

export default PastVisitsCollection
