import { InferType, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  imageId: string().required(),
})

export type Request = InferType<typeof Schema>
export type SuccessfulResponse = Blob

export const url = ({ memberId, imageId }) => `/v2/members/${memberId}/images/thumb/${imageId}.png`

export const responseType = 'blob'
