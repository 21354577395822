import moment from 'moment'
import yesNo from './yesNo'

export const getLifestyleAnswer = (value: boolean | null | undefined, drinkFrequency?: string | null): string => {
  if (typeof value === 'boolean') {
    if (value && drinkFrequency) {
      return drinkFrequency
    }
    return yesNo(value)
  }
  return 'Not Answered'
}

export const parseMedicationDate = (date?: string) => (date ? moment(new Date(date)).utc().format('MM-DD-YYYY') : null)

export const getMedicationDateTimestamp = (date?: string) => (date ? moment(date, 'MM-DD-YYYY').valueOf() : null)

export const formatMedicationDateToApi = (date?: string) =>
  date ? moment(getMedicationDateTimestamp(date)).format('YYYY-MM-DD') : null
