export const GENDER_MALE_LABEL = 'Male'
export const GENDER_FEMALE_LABEL = 'Female'
export const GENDER_SELF_SELECTED_LABEL = 'Self Selected'

export const GENDERS_MAP = {
  M: GENDER_MALE_LABEL,
  F: GENDER_FEMALE_LABEL,
  X: GENDER_SELF_SELECTED_LABEL,
}

export const DOSESPOT_GENDERS_MAP = {
  1: GENDER_MALE_LABEL,
  2: GENDER_FEMALE_LABEL,
}

export const genderOptions = ['M', 'F', 'X'].map((value) => ({
  value,
  label: GENDERS_MAP[value],
}))
