import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
})

/* Returns array of visitRecords(not pending) */
export const url = () => `/v1/prescriptions/fill`

export const getBody = ({ visitId }) => ({ visitId })
