import { object, string, boolean, number, array } from 'yup'
import { getMedicationDateTimestamp } from 'util/patient'

export const ConditionSchema = object().shape({
  condition: string().required(),
  conditionId: string().required(),
  id: string(),
  name: string().required(),
  value: boolean(),
})

export const OtherConditionSchema = object().shape({
  id: string().notRequired(),
  conditionId: string().notRequired(),
  value: string().required(),
})

export const medicationStatuses = ['Current', 'Discontinued']
export const MedicationSchema = object().shape({
  name: string().label('Medication Name').min(3).required(),
  strengthAndUnit: string().label('Strength').notRequired().nullable(),
  unit: string().label('Dispense Unit').required(),
  startDate: string().label('Start Date').notRequired().nullable().default(null),
  endDate: string()
    .label('End Date')
    .notRequired()
    .nullable()
    .default(null)
    .test('minEndDate', 'End Date must be later than Start Date', function (endDate) {
      const { startDate } = this.parent || {}

      const startDateTimestamp = getMedicationDateTimestamp(startDate)
      const endDateTimestamp = getMedicationDateTimestamp(endDate)
      return !startDateTimestamp || !endDateTimestamp || endDateTimestamp >= startDateTimestamp
    }),
  direction: string()
    .max(500, 'Directions must be at most 500 characters')
    .label('Directions')
    .notRequired()
    .nullable()
    .default(null),
  status: string().label('Status').oneOf(medicationStatuses).required(),
  dispensableDrugId: string().notRequired().nullable().default(null),
  lastUpdatedBy: string().notRequired().nullable().default(null),
  lastUpdatedByType: string().oneOf(['provider']).required().default('provider'),
})

const MedicationSchemaBase = object({
  id: string().required(),
  name: string().required(),
})

export const MedicalHistorySchema = object().shape({
  memberId: string().required(),
  heightFt: number(),
  heightIn: number(),
  weightLbs: number(),
  // bpSystolic: number()
  //   .notRequired()
  //   .default(null),
  // bpDiastolic: number()
  //   .notRequired()
  //   .default(null),
  lifestyle: object(),
  conditions: array().of(ConditionSchema).default([]),
  other: array().of(OtherConditionSchema).default([]),
  medications: array().of(MedicationSchemaBase).default([]),
  allergiesCompleted: boolean().default(false),
  medicationsCompleted: boolean().default(false),
  conditionsCompleted: boolean().default(false),
  familyHistoryCompleted: boolean().default(false),
  primaryCareCompleted: boolean().default(false),
})
