import { runInAction, observable } from 'mobx'
import throttle from 'lodash/throttle'
import makeQuery from 'api/makeQuery'

/*
    Some USA laws do not allow to store patient name, surname and diagnose in one db record,
    so they are stored separately.
*/

class Pharmacies {
  byId = new Map()
  fetchQueue = new Set()

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const ids = Array.from(this.fetchQueue)

    for (const id of ids) {
      const res = await makeQuery('getPharmacy', { pharmacyId: id })
      runInAction(() => {
        this.byId.get(id).set(res)
      })
      this.fetchQueue.delete(id)
    }

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 500)

  get(id) {
    let res = this.byId.get(id)

    if (!res) {
      res = observable.box()
      this.byId.set(id, res)
      this.fetchQueue.add(id)
      this.processQueue()
    }

    return res.get()
  }
}

export default new Pharmacies()
