import { InferType, object, string } from 'yup'
import { Schedule } from 'types/Provider/Schedule'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  scheduleId: string().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = Schedule

export const url = ({ scheduleId, providerId }: Request) => `/v2/providers/${providerId}/schedules/${scheduleId}`
