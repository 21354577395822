import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  phoneId: string().required(),
})

export const url = ({ providerId, phoneId }) => `/v1/providers/${providerId}/phone/${phoneId}/verification/send`
