/* Transforms 

[ '7:00', '17:00' ],
[ '13:00', '17:00' ],
[ '17:10', '18:00' ]

===>

[ '7:00', '17:00' ],
[ '17:10', '18:00' ] */

const compareTimes = (a, b) => new Date(`01/01/2000 ${a}`) - new Date(`01/01/2000 ${b}`)

const mergeRanges = (ranges, compare = compareTimes) => {
  if (ranges.length < 2) {
    return ranges
  }

  const sortedRanges = ranges.slice().sort((a, b) => compare(a[0], b[0]))

  const stack = [sortedRanges[0].slice()]

  for (let i = 1; i < sortedRanges.length; i++) {
    const [from, to] = sortedRanges[i]
    const top = stack.at(-1)

    if (compare(top[1], from) < 0) {
      stack.push([from, to])
    } else if (compare(top[1], to) < 0) {
      top[1] = to
    }
  }

  return stack
}

export default mergeRanges
