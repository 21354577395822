import { makeObservable, runInAction, onBecomeObserved } from 'mobx'
import { find, findIndex } from 'lodash'
import FamilyHistory from './FamilyHistory'
import pick from 'util/pick'
import makeQuery from 'api/makeQuery'
import MedicalHistory from './MedicalHistory'
import MemberExtendedData from '..'

class PrivateData {
  constructor(valuesObj) {
    Object.assign(this, pick(valuesObj, ['member', 'dependents', 'medicalHistory', 'allergies']))
    this.familyHistory = new FamilyHistory(this, valuesObj.familyHistory)
    this.medicalHistory = new MedicalHistory(this, valuesObj.medicalHistory)
    this.possibleConditions = []
    makeObservable(this, {
      addAllergy: true,
      deleteAllergy: true,
      editAllergy: true,
      allergies: true,
      dependents: true,
      familyHistory: true,
      medicalHistory: true,
      member: true,
      possibleConditions: true,
    })
    this.disposePossibleConditionsFetcher = onBecomeObserved(this, 'possibleConditions', () => {
      // If this does not need fetched frequently
      //   if(this.possibleConditions) {
      //    this.resetPossibleConditions()
      //    }
      this.resetPossibleConditions()
    })
  }

  async resetPossibleConditions() {
    const possibleConditions = await makeQuery('getPossibleConditions')
    runInAction(() => {
      this.possibleConditions = possibleConditions
    })
  }

  destroy() {
    this.disposePossibleConditionsFetcher()
    this.familyHistory.destroy()
  }

  async addAllergy(submission) {
    const newAllergy = await makeQuery('postAllergies', {
      allergy: submission,
      memberId: this.member.memberId,
    })
    runInAction(() => {
      this.allergies.push(newAllergy)
    })
  }

  async editAllergy(allergyId, submission) {
    const editedAllergy = await makeQuery('putAllergies', {
      allergy: submission,
      allergyId: allergyId,
    })
    const currentAllergy = find(this.allergies, {
      allergyId: editedAllergy.allergyId,
    })
    runInAction(() => {
      if (currentAllergy) {
        Object.assign(currentAllergy, editedAllergy)
      }
    })
  }

  async deleteAllergy(id) {
    await makeQuery('deleteAllergies', {
      allergyId: id,
    })
    runInAction(() => {
      const index = findIndex(this.allergies, { allergyId: id })
      if (index !== -1) {
        this.allergies.splice(index, 1)
      }
    })
  }

  async updateStudentInfo(type, submission) {
    await makeQuery('putStudentInfo', {
      [type]: submission,
      memberId: this.member.memberId,
    }).then(() => {
      MemberExtendedData.refetch(this.member.memberId)
    })
  }
}

export default PrivateData
