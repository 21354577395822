import { makeObservable, observable } from 'mobx'
import Period from './Period'
const DEFAULT_PERIOD = [null, null]
class BlackoutDatesCollection {
  dates = []
  isDateDeleted = false
  constructor(provider) {
    makeObservable(this, {
      dates: observable.shallow,
      isDateDeleted: true,
      addPeriod: true,
      deletePeriod: true,
      recalc: true,
      isDirty: true,
      hasEmpty: true,
    })
    this.provider = provider
  }
  // We use unshift instead of push to add period at start of list so user can see it
  addPeriod = () => {
    this.dates.unshift(new Period(this, DEFAULT_PERIOD, true))
  }
  deletePeriod = (id) => {
    const periodIndex = this.dates.findIndex((date) => date.id === id)
    if (periodIndex !== -1) {
      this.dates.splice(periodIndex, 1)
      this.isDateDeleted = true
    }
  }
  recalc = () => {
    this.dates = this.provider?.schedule?.blackouts?.map((blackout) => new Period(this, blackout)) || []
  }
  forDate(date) {
    return this.dates.filter((period) => period.touchesDay(date))
  }
  // Add destroy functionality later if needed
  destroy() {}
  get isDirty() {
    return this.isDateDeleted || this.dates.some((date) => date.isChanged) || this.provider.isAcceptingVisitsDirty
  }
  get hasEmpty() {
    return this.dates.some((el) => !el.ready)
  }
}
export default BlackoutDatesCollection
