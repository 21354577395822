import { object, string } from 'yup'

export { auth as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  email: string().email().required(),
})

export const url = () => '/v1/resend-verification'

export const getBody = ({ email }) => ({
  email,
  user_type: 'provider',
})
