import { object, string, array } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  major: array().of(string()).default([]),
  student_experiences: array().of(string()).default([]),
  degree_classification: string(),
  graduation_semester: string(),
  graduation_year: string(),
})

export const url = ({ memberId }) => `/v2/members/${memberId}`

export const getBody = ({
  major,
  student_experiences,
  degree_classification,
  graduation_semester,
  graduation_year,
}) => ({
  member: {
    major,
    student_experiences,
    degree_classification,
    graduation_semester,
    graduation_year,
  },
})
