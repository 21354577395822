import { action, makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import chatMessageSound from './chat-message.wav'
import { Link } from 'react-router-dom'

const MsgSound = new Audio(chatMessageSound)

class ChatMessageNotifier {
  visitIdToIgnore = new Set<string>()

  constructor() {
    makeAutoObservable(this, { toggle: action, notify: false })
  }

  toggle(visitId: string, state: boolean) {
    if (state) {
      this.visitIdToIgnore.add(visitId)
    } else {
      this.visitIdToIgnore.delete(visitId)
    }
  }

  notify(visitId: string) {
    if (!this.visitIdToIgnore.has(visitId)) {
      toast.info(
        <div>
          New message in&nbsp;
          <Link to={`/visits/${visitId}`} className="underline">
            visit
          </Link>
        </div>,
      )
      MsgSound.play()
    }
  }
}

export default new ChatMessageNotifier()
