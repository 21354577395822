import { mfaOptions, mfaOptionsMap } from 'constants/mfaOptions'
import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  deliveryMethod: string().oneOf(mfaOptions).required(),
  phoneId: string().when('deliveryMethod', {
    is: (method) => method === mfaOptionsMap.sms,
    then: string().required(),
    otherwise: string().notRequired(),
  }),
})

export const url = () => `/v1/me/generate-mfa`

export const getBody = (body) => body
