import { makeObservable } from 'mobx'
import mapValues from 'lodash/mapValues'
import makeQuery from 'api/makeQuery'
import Record from 'models/Record'
import mergeMobxListsBy from 'util/mergeMobxListsBy'

import type { PendingSlot, Schedule as ScheduleType, TimeSlot as TimeSlotType } from 'types/Provider/Schedule'

import SchedulingsV2Collection from '..'
import TimeSlot from './TimeSlot'

export const SCHEDULE_DEFAULTS = {
  scheduleId: '',
  providerId: '',
  date: '',
  dateFormattedMmdy: '',
  dateFormattedMdy: '',
  dayOfWeekNumber: 0,
  dayOfWeekName: '',
  dayOfWeekNameAbbrev: '',
  dayOfWeekNameShort: '',
  dayOfWnthNumber: '',
  monthName: '',
  monthNameShort: '',
  monthNumber: 0,
  yearNumber: 0,
  createdAt: '',
  updatedAt: '',
}

type ScheduleOmittedSlots = Omit<ScheduleType, 'slots'>

interface Schedule extends ScheduleType {}
class Schedule extends Record {
  static INITIAL_FIELDS: ScheduleOmittedSlots = SCHEDULE_DEFAULTS

  /** [slotId, TimeSlot] */
  slotMap: Map<string, TimeSlot> = new Map()
  slots: TimeSlot[] = []
  root: SchedulingsV2Collection

  constructor(schedule: ScheduleType, root: SchedulingsV2Collection) {
    super(schedule)

    this.root = root

    schedule.slots && this.mergeSlots(schedule.slots, true)

    makeObservable(this, {
      // @ts-ignore
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      slots: true,
      slotMap: true,
      createSlot: true,
      sectionedList: true,
      mergeSlots: true,
    })
  }

  mergeSlots(slots: TimeSlotType[], shouldDelete: boolean) {
    mergeMobxListsBy(
      this.slotMap,
      this.slots,
      slots,
      'slotId',
      (slot: TimeSlotType) => new TimeSlot(slot, this),
      shouldDelete,
    )
  }

  mergeScheduleAndSlots(schedule: ScheduleType) {
    this.merge(schedule)
    this.mergeSlots(schedule.slots, true)
  }

  get commonParams() {
    return {
      providerId: this.providerId,
      scheduleId: this.scheduleId,
    }
  }

  get sectionedList() {
    return this.sectionedList.reduce(
      (acc, cur) => {
        if (cur.visit) {
          acc.visits.push(cur)
        } else {
          acc.slots.push(cur)
        }
        return acc
      },
      { slots: [], visits: [] },
    )
  }

  async createSlot(slot: PendingSlot) {
    const createdSlot = await makeQuery('postScheduleSlot', { ...this.commonParams, slot })
    this.mergeSlots([createdSlot], false)
  }
}

export default Schedule
