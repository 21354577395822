import { makeAutoObservable, observable } from 'mobx'

class DischargeInstructions {
  constructor(visit, initialValues) {
    /* Array of: { documentId, filename, name } */
    this.list = initialValues || []

    makeAutoObservable(this, {
      destroy: false,
      list: observable.shallow,
      map: false,
    })

    this.visit = visit
  }

  get length() {
    return this.list.length
  }

  map(cb) {
    return this.list.map(cb)
  }

  replace(arr) {
    this.list.replace(arr || [])
  }

  add(documentId, filename, name) {
    // TODO: add language field when API updated
    this.list.push({ documentId, filename, name })
  }

  delete(documentId) {
    const instructionToDeleteIndex = this.list.findIndex((instruction) => instruction.documentId === documentId)
    if (instructionToDeleteIndex !== -1) {
      this.list.splice(instructionToDeleteIndex, 1)
    }
  }
}

export default DischargeInstructions
