import { makeObservable, runInAction, observable, autorun, action } from 'mobx'
import Pusher from 'pusher-js'
import PusherBatchAuthorizer from 'pusher-js-auth'
import User from 'singletons/User'
import reportError from 'util/reportError'

const LOGGING_MODE = true

class PusherSingleton {
  client = null

  subscriptionQueue = new Set()
  unsubscriptionQueue = new Set()
  currentlySubscribedTopics = new Set()

  listeners = new Set()

  listen(fn) {
    this.listeners.add(fn)
    return () => this.listeners.delete(fn)
  }

  processMessage = (topic, msg) => {
    try {
      if (topic === 'pusher:error') {
        if (Number(msg?.code) !== 4201 && !User.getFeatureFlagByName('pwPusherErrorNotLogout')) {
          User.logout()
        }
      } else {
        for (const listener of this.listeners) {
          listener(msg)
        }
      }
      if (LOGGING_MODE) {
        console.log(`Pusher message received(${topic}): `, msg)
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(topic, msg)
      }
      reportError('Pusher parse message', err)
    }
  }

  start(token) {
    this.client?.unbind()
    this.client?.disconnect()
    this.client = null

    if (token) {
      this.client = new Pusher(process.env.REACT_APP_KEY, {
        authorizer: PusherBatchAuthorizer,
        authDelay: 200,
        cluster: process.env.REACT_APP_CLUSTER,
        authEndpoint: process.env.REACT_APP_ENDPOINT,
        auth: {
          headers: { Authorization: `Bearer ${token}` },
        },
      })

      console.log('%cStarting Pusher', 'color: olive; font-size: 1.4em; text-align: center; font-weight: 600;')

      this.client.bind_global(this.processMessage)
      this.resubscribeAllCurrentSubscriptions()
    }
  }

  constructor() {
    makeObservable(this, {
      client: observable.ref,
      start: action,
      resubscribeAllCurrentSubscriptions: action,
      subscriptionQueue: true,
      unsubscriptionQueue: true,
      subscribeChannel: true,
      unsubscribeChannel: true,
    })

    autorun(
      () => {
        if (this.client) {
          this.processQueue(this.unsubscriptionQueue, this.client.unsubscribe)
          this.processQueue(this.subscriptionQueue, this.client.subscribe)
        }
      },
      { delay: 500 },
    )
  }

  processQueue(queue, clientMethod) {
    if (this.client && queue.size) {
      for (const evt of queue) {
        clientMethod.call(this.client, evt)
      }
      runInAction(() => {
        queue.clear()
      })
    }
  }

  subscribeChannel(topic) {
    this.unsubscriptionQueue.delete(topic)
    this.subscriptionQueue.add(topic)
    this.currentlySubscribedTopics.add(topic)
  }

  unsubscribeChannel(topic) {
    this.subscriptionQueue.delete(topic)
    this.unsubscriptionQueue.add(topic)
    this.currentlySubscribedTopics.delete(topic)
  }

  resubscribeAllCurrentSubscriptions() {
    if (this.currentlySubscribedTopics.size) {
      console.log(
        '%cResubscribing pusher topics',
        'color: olive; font-size: 1.4em; text-align: center; font-weight: 600;',
        Array.from(this.currentlySubscribedTopics),
      )
      for (const topic of this.currentlySubscribedTopics) {
        this.subscriptionQueue.add(topic)
        this.unsubscriptionQueue.delete(topic)
      }
    }
  }
}

export default new PusherSingleton()
