export const LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  ar: 'Arabic',
  hy: 'Armenian',
  zh: 'Chinese',
  de: 'German',
  fr: 'French',
  he: 'Hebrew',
  hi: 'Hindi',
  ja: 'Japanese',
  ko: 'Korean',
  ru: 'Russian',
}

export const languagesOptions = Object.keys(LANGUAGES).map((value) => ({
  value,
  label: LANGUAGES[value],
}))
