import { object, string, InferType, boolean } from 'yup'
import type Visit from 'types/Visit'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  visitId: string().required(),
  form: object().notRequired(),
  attestation: boolean().notRequired(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = Visit

export const url = ({ visitId }: Request) => `/v1/visits/${visitId}/safety-plan`

export const getBody = ({ form, attestation }: Request) => ({ form, attestation })
