import { makeAutoObservable, onBecomeObserved, observable, when, action, makeObservable } from 'mobx'
import makeQuery from 'api/makeQuery'
import User from 'singletons/User'
import { AES, enc } from 'crypto-js'
import { formatDate } from 'util/formatters'
import format from 'date-fns/format'

const MAX_MESSAGES = 500

const encrypt = (text: string, key: string) => AES.encrypt(text, key).toString()

const decrypt = (cipher: string, key: string) => {
  try {
    const bytes = AES.decrypt(cipher, key)
    return bytes.toString(enc.Utf8)
  } catch (e) {
    return ''
  }
}

export type RawMessage = {
  id: string
  senderId: string
  message: string
  createdAt: string
  viewedAt: string | null
}

export interface ChatMessage extends RawMessage {
  createdDate: string
  createdTime: string
  rawCreatedDate: Date
  rawViewedDate: Date | null
}

export type PutChatMessageResponse = {
  /** currently unused, everything is calculated from timestamps */
  count: number

  /**
   * currently unused;
   * contains further unread messages going after the read ones (if read one is not the last one)
   * should not normally be used, because all new messages are added by pusher event
   */
  messages: RawMessage[]

  /** array of messages ( only viewedAt's ), affected by read attempt */
  updatedMessages: { id: RawMessage['id']; viewedAt: string }[]
}

export class ChatMessage {
  root: Chat

  constructor(root: Chat, initial: RawMessage) {
    this.root = root
    const rawCreatedDate = new Date(initial.createdAt)
    Object.assign(this, {
      ...initial,
      rawCreatedDate,
      createdDate: formatDate(rawCreatedDate),
      createdTime: format(rawCreatedDate, 'hh:mm aaa'),
      message: initial.message,
    })

    this.updateViewedAt(initial.viewedAt)

    makeObservable(this, {
      viewedAt: true,
      rawViewedDate: true,
      updateViewedAt: action,
    })
  }

  get my() {
    return this.senderId === this.root.mySenderId
  }

  get read() {
    return this.my || !!this.viewedAt
  }

  updateViewedAt(viewedAt: string | null) {
    this.viewedAt = viewedAt
    this.rawViewedDate = viewedAt ? new Date(viewedAt) : null
  }

  markRead() {
    this.root.markRead(this)
  }
}

/* TODO: Remove this after upgrading typescript to version > 5 */
declare global {
  interface Array<T> {
    findLast(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined
  }
}

class Chat {
  visit: any
  private disposeWhen: (() => void) | null = null
  private disposeChatFetcher: () => void

  createdAt = ''
  deletedAt = ''
  updatedAt = ''
  messages: ChatMessage[] = []
  participants = []

  private messagesById = new Map<string, ChatMessage>()

  constructor(visit: any) {
    makeAutoObservable<Chat, 'disposeWhen' | 'disposeChatFetcher' | 'messagesById'>(this, {
      destroy: false,
      messagesById: false,
      sendMessage: false,
      disposeWhen: false,
      disposeChatFetcher: false,
      markRead: false,
      addMessage: action,
      viewMessages: action,
      clear: action,
      messages: observable.shallow,
      participants: observable.shallow,
    })

    this.visit = visit

    this.disposeChatFetcher = onBecomeObserved(this, 'messages', () => this.refetch())
  }

  clear() {
    this.messagesById.clear()
    this.messages = []
    this.participants = []
  }

  get fetched() {
    /* must touch messages here */
    return this.messages && !!this.createdAt
  }

  get length() {
    return this.messages.length
  }

  private get encryptionKey() {
    const memberPrivateKey = this.visit.memberExtendedData?.privateData.member.publicKey

    if (!memberPrivateKey) {
      return ''
    }

    const providerPrivateKey = User.provider?.publicKey

    if (!providerPrivateKey) {
      return ''
    }

    return memberPrivateKey + providerPrivateKey
  }

  get mySenderId() {
    const participant = this.participants.find(({ userType }) => userType === 'provider')
    return participant ? (participant as any).id : ''
  }

  addMessage({ message, id, createdAt, viewedAt, senderId }: RawMessage) {
    if (message) {
      let messageModel = this.messagesById.get(id)

      if (messageModel) {
        messageModel.updateViewedAt(viewedAt)
      } else {
        const decrypted = decrypt(message, this.encryptionKey).trim()

        if (decrypted) {
          messageModel = new ChatMessage(this, {
            id,
            senderId,
            createdAt,
            viewedAt,
            message: decrypted,
          })

          this.messagesById.set(id, messageModel)
          this.messages.push(messageModel)
        }
      }
    }
  }

  viewMessages(messages: { id: string; viewedAt: string }[]) {
    messages.forEach(({ viewedAt, id }) => this.messagesById.get(id)?.updateViewedAt(viewedAt))
  }

  async sendMessage(message: string) {
    if (!this.visit.chatId) {
      console.log({ visit: this.visit })
      throw new Error('You are trying to send a message with empty visit.chatId')
    }

    await when(() => this.encryptionKey)
    await makeQuery('postChatMessage', {
      chatId: this.visit.chatId,
      message: {
        senderId: this.mySenderId,
        tagIds: [],
        message: encrypt(message.trim(), this.encryptionKey),
      },
    })
  }

  private *refetchRaw() {
    const { createdAt, updatedAt, deletedAt, messages, participants } = yield makeQuery('getChat', {
      chatId: this.visit.chatId,
    })

    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.participants = participants

    if (messages) {
      for (let len = messages.length, i = Math.max(0, len - MAX_MESSAGES); i < len; i++) {
        this.addMessage(messages[i])
      }
    }
  }

  refetch() {
    this.disposeWhen && this.disposeWhen()
    this.disposeWhen = when(
      () => this.visit.chatId && this.encryptionKey,
      () => this.refetchRaw(),
    )
  }

  async markRead(message: ChatMessage) {
    const { updatedMessages }: PutChatMessageResponse = await makeQuery('putReadChatMessage', {
      messageId: message.id,
    })
    this.viewMessages(updatedMessages)
  }

  get lastReceivedMessage() {
    return this.messages.findLast((m) => !m.my)
  }

  destroy() {
    this.clear()
    this.disposeWhen && this.disposeWhen()
    this.disposeChatFetcher()
  }
}

export default Chat
