import { object, number, string } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  filter: object().shape({
    member_id: string(),
    intake_type: string(),
  }),
  sort: object().shape({
    scheduled_at: string(),
  }),
  page: object().shape({
    skip: number(),
    limit: number(),
  }),
})

export const url = ({ filter, sort, page }) =>
  `/v1/visits/pending/intakes?${queryString.stringify({ filter, sort, page })}`
