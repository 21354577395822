import { object, string } from 'yup'

import VisitTypes from 'constants/visit-types'
import { statesWithAsterix } from 'constants/states'

export const LicenseSchema = object().shape({
  id: string(),
  type: string()
    .oneOf(
      [VisitTypes.medical, VisitTypes.therapy, VisitTypes.psychiatry, VisitTypes.healthCoach, VisitTypes.success],
      'Must be a valid license type',
    )
    .required('Required'),
  number: string().required('Required'),
  state: string().oneOf(statesWithAsterix, 'Must be a valid state').required('Required'),
  expDate: string()
    .matches(/^(\d{4}-\d{2}-\d{2})$/, 'Must be a valid date in YYY-MM-DD format')
    .required('Required'),
})
