import { object, string, array } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  schedulingId: string().required(),
  times: array().required(),
  note: string(),
})

export const url = ({ providerId, schedulingId }) =>
  `/v1/providers/${providerId}/schedulings/${schedulingId}/reject/provider`

export const getBody = ({ times, note }) => ({ times, note })
