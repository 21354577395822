import { InferType, object, string } from 'yup'

export const TimeSlotBaseSchema = object({
  type: string().required(),
  startTime: string().required(),
})

export type TimeSlotFormValues = InferType<typeof TimeSlotBaseSchema>

export const TimeSlotPendingSchema = object({
  endTime: string().required(),
  permission: string().required(),
}).concat(TimeSlotBaseSchema)
