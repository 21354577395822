import makeQuery from 'api/makeQuery'
import jwtDecode from 'jwt-decode'
import { makeAutoObservable, runInAction } from 'mobx'
import { SETTIMEOUT_MAX_VALUE } from 'constants/timers'
import safeJsonParse from 'util/safeJsonParse'
import { isDosespotNotConfirmed } from 'util/login'

const LS_NAME = 'MFA_TOKENS_MAP'

/** backend returns timestamp w/out milliseconds */
type SecondsTimestamp = number

/** MfaOption is either 'email' or a phoneId returned from api */
type MfaOption = 'email' | string

const getTokenTimeDiff = (token: string) => {
  const { exp }: { exp: SecondsTimestamp } = jwtDecode(token)
  return Math.min(exp * 1000 - Date.now(), SETTIMEOUT_MAX_VALUE)
}

type Phone = {
  phoneNumber: string
  phoneId: string
}
type MfaChoices = {
  email: string
  phones: Phone[]
}

const buildMfaChoice = (option: MfaOption) =>
  option === 'email'
    ? { deliveryMethod: option }
    : {
        deliveryMethod: 'sms',
        phoneId: option,
      }
class Mfa {
  root: any
  token = ''
  private refreshMfaToken = ''
  choices: MfaChoices | null = null
  temporaryChoice: string | null = null
  expired = false
  private tokensByEmail = new Map<string, string>(Object.entries(safeJsonParse(localStorage.getItem(LS_NAME)) || []))
  private email = ''
  private timeout: ReturnType<typeof setTimeout> | null = null

  // TODO: type root model when User singleton is typed
  constructor(root: any) {
    this.root = root
    makeAutoObservable<Mfa, 'timeout' | 'clearTimer' | 'tokensByEmail' | 'getTokenForEmail'>(this, {
      timeout: false,
      clearTimer: false,
      tokensByEmail: false,
      getTokenForEmail: false,
      root: false,
    })
  }

  get inputOptions() {
    return (
      this.choices?.email
        ? [
            {
              label: this.choices.email,
              value: 'email',
            },
          ]
        : []
    ).concat(
      this.choices?.phones.map((phone: Phone) => ({
        label: phone.phoneNumber,
        value: phone.phoneId,
      })) || [],
    )
  }

  generateMfa(option: MfaOption) {
    this.temporaryChoice = option
    return makeQuery('postGenerateMfa', buildMfaChoice(option), 'mfa')
  }

  resendMfa() {
    return this.temporaryChoice && this.generateMfa(this.temporaryChoice)
  }

  private persistToken(rememberDevice: boolean) {
    if (this.email) {
      if (rememberDevice && this.refreshMfaToken && getTokenTimeDiff(this.refreshMfaToken) > 0) {
        this.tokensByEmail.set(this.email, this.refreshMfaToken)
      } else {
        this.tokensByEmail.delete(this.email)
      }
      localStorage.setItem(LS_NAME, JSON.stringify(Object.fromEntries(this.tokensByEmail)))
    }
  }

  async verifyMfaLogin(mfaCode: string, rememberDevice: boolean) {
    const data = await makeQuery(
      'postVerifyMfa',
      {
        mfaCode,
      },
      'mfa',
    )

    if (isDosespotNotConfirmed(data)) {
      return data
    }

    runInAction(() => {
      const { auth, provider, providerId, passwordExpired, resources } = data
      this.refreshMfaToken = auth.refreshMfaToken
      this.reset(!rememberDevice)
      this.root.setPasswordExpired(passwordExpired)
      this.root.auth.initialize(auth)
      this.root.initializeProvider(providerId, provider, resources)
      this.persistToken(rememberDevice)
    })
    return data
  }

  private clearTimer() {
    this.timeout && clearTimeout(this.timeout)
  }

  getTokenForEmail(email: string) {
    const token = this.tokensByEmail.get(email)
    return token && getTokenTimeDiff(token) > 0 ? token : undefined
  }

  initialize(choices: MfaChoices, token: string, email: string) {
    this.clearTimer()

    const timeDiff = getTokenTimeDiff(token)

    this.token = token
    this.choices = choices
    this.email = email
    this.expired = timeDiff < 0

    if (!this.expired) {
      this.timeout = setTimeout(() => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('time left hours: ', timeDiff / (1000 * 60 * 60))
        }
        runInAction(() => {
          this.expired = true
        })
      }, timeDiff)
    }
  }

  reset(shouldClearTokens: boolean) {
    if (shouldClearTokens) {
      this.token = this.refreshMfaToken = ''
    }
    this.choices = null
    this.temporaryChoice = null
    this.clearTimer()
  }
}

export default Mfa
