import { makeObservable, runInAction } from 'mobx'
import differenceInYears from 'date-fns/differenceInYears'
import mapValues from 'lodash/mapValues'

import makeQuery from 'api/makeQuery'
import MemberPrivateData from 'singletons/MemberPrivateData'
import User from 'singletons/User'
import GlobalEvents from 'singletons/GlobalEvents'
import Record from 'models/Record'
import Availability from 'models/Provider/Availability'
import { statesByValue } from 'constants/states'
import { countriesByValue } from 'constants/countries'

class SchedulingBase extends Record {
  static INITIAL_FIELDS = {
    accepted: false,
    cancelled: false,
    rejected: false,
    clientId: '',
    createdAt: '',
    createdBy: '',
    groupId: '',
    note: '',
    memberDob: '',
    memberId: '',
    memberTimezone: '',
    paymentToken: '',
    providerFullName: '',
    providerId: '',
    schedulingId: '',
    times: [],
    updatedAt: '',
    visitLocation: '',
    visitLocationCountry: '',
    visitModality: '',
    visitReason: '',
    visitTimezone: '',

    /* comes later */
    visitType: '',
  }

  availability = new Availability(this)

  constructor(schedule) {
    super(schedule)

    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    })
  }

  destroy() {
    this.availability.destroy()
  }

  async cancel(reason) {
    const scheduling = await makeQuery('putProviderSchedulingsCancel', {
      providerId: User.providerId,
      schedulingId: this.schedulingId,
      reason,
    })

    GlobalEvents.emit('cancelProviderSchedulings', {
      status: 'success',
      memberFullName: this.memberFullName,
      reason,
    })

    this.merge(scheduling)
  }

  async reject({ times, note }) {
    const scheduling = await makeQuery('putProviderSchedulingsReject', {
      providerId: User.providerId,
      schedulingId: this.schedulingId,
      times,
      note,
    })
    runInAction(() => {
      this.merge(scheduling)
      /* not current, but new sceduling is returned on reject. Need to update this manually. */
      this.rejected = true
    })

    GlobalEvents.emit('rejectProviderSchedulings', {
      status: 'success',
    })
  }

  async confirm(time, memberFullName) {
    const scheduling = await makeQuery('putProviderSchedulingsAccept', {
      providerId: User.providerId,
      schedulingId: this.schedulingId,
      time,
    })

    GlobalEvents.emit('confirmSchedulingVisit', {
      status: 'success',
      memberFullName,
    })

    this.merge(scheduling)
  }

  get age() {
    return differenceInYears(Date.now(), new Date(this.memberDob))
  }

  get fullLocation() {
    return this.visitLocation ? statesByValue[this.visitLocation] : countriesByValue[this.visitLocationCountry]
  }

  get privateData() {
    return MemberPrivateData.get(this.memberId)
  }

  get memberFirstName() {
    return this.privateData?.preferredName ?? ''
  }
  get memberLastName() {
    return this.privateData?.lastName ?? ''
  }
  get memberFullName() {
    return this.privateData?.fullName ?? ''
  }
}

export default SchedulingBase
