import { object, string } from 'yup'

export { auth as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  email: string().email('Invalid email').required('Required'),
  userType: string().required('Required'),
})

export const url = () => `/v1/forgot`

export const getBody = ({ email, userType }) => ({ email, userType })
