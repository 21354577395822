import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/react'

export const initialize = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT_SPECIFIC,
    integrations: [browserTracingIntegration()],
    denyUrls: [/^(http|https):\/\/localhost:.+/g],
    release: 'provider-web@' + process.env.REACT_APP_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'production' ? 0.1 : 0.5,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Invalid provider.',
      // 'Non-Error promise rejection captured with value: Request failed with status code 401',
      // 'Non-Error promise rejection captured with value: Token is not valid',
      // 'Non-Error promise rejection captured with value: Not Authorized',
      'UnhandledRejection: Non-Error promise rejection captured with value: Invalid provider.',
      /** Error occurs from @floating-ui/react in ui-comp-3 <Select/> when used inside drawer (only when menu height would overflow doc, due to iten count).
       * Per lib's creator, error is harmless and can be ignored:
       * https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1563544849
       */
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  })

  Sentry.setTag('app-version', process.env.REACT_APP_VERSION)
}

export const captureEvent = (error: Sentry.Event) => {
  Sentry.captureEvent(error)
}

export const captureException = (error: Error) => {
  Sentry.captureException(error)
}

export const captureMessage = (message: string) => {
  Sentry.captureMessage(message)
}

export const setUser = (user: Sentry.User | null) => {
  Sentry.setUser(user)
}
