import { observer } from 'mobx-react-lite'
import Modals from 'singletons/Modals'
import V2ModalsMap from './v2Modals'
import ModalWrapperV2 from './ModalWrapperV2'
import ModalWrapper from './ModalWrapper'

const ModalsContainer = () =>
  Modals.list.map((modalParams) =>
    modalParams.type in V2ModalsMap ? (
      <ModalWrapperV2 key={modalParams.id} {...modalParams} />
    ) : (
      <ModalWrapper key={modalParams.id} {...modalParams} />
    ),
  )
export default observer(ModalsContainer)
