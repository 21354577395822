import { InferType, object, string } from 'yup'
import CrisisEscalation from 'types/Visit/PendingVisit/CrisisEscalation'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  visitId: string().required(),
})

export type SuccessfulResponse = CrisisEscalation[]

export type Request = InferType<typeof Schema>

export const url = ({ visitId }) => `/v1/visits/pending/${visitId}/escalations`
