import { makeObservable, action, observable } from 'mobx'
import Provider from 'models/Provider'
import makeQuery from 'api/makeQuery'
import throttle from 'lodash/throttle'

class Providers {
  byId = new Map()
  fetchQueue = new Set()

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const ids = Array.from(this.fetchQueue)

    for (const id of ids) {
      const providerInfo = await makeQuery('getProvider', { id })
      if (process.env.NODE_ENV !== 'production') {
        console.log('Fetched provider: ', providerInfo)
      }
      this.upsert(id, providerInfo)
      this.fetchQueue.delete(id)
    }

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 500)

  constructor() {
    makeObservable(this, {
      upsert: action,
    })
  }

  get(providerId) {
    let providerBox = this.byId.get(providerId)

    if (!providerBox) {
      providerBox = observable.box()
      this.byId.set(providerId, providerBox)
      this.fetchQueue.add(providerId)
      this.processQueue()
    }

    return providerBox.get()
  }

  clear() {
    for (const provider of this.byId.values()) {
      provider.get()?.destroy()
    }
    this.processQueue.cancel()
    this.byId.clear()
  }

  upsert(providerId, providerInfo) {
    let providerBox = this.byId.get(providerId)

    if (!providerBox) {
      providerBox = observable.box()
      this.byId.set(providerId, providerBox)
    }

    let provider = providerBox.get()

    if (provider) {
      provider.initialize(providerInfo)
    } else {
      provider = new Provider(providerInfo)
      providerBox.set(provider)
    }
  }
}

export default new Providers()
