import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  visitId: string().required(),
  visitType: string().required(),
})

export const url = ({ providerId, visitId }) => `/v1/providers/${providerId}/visits/queue/claim/${visitId}`

export const getBody = ({ providerId, visitType }) => ({ providerId, visitType })
