import { makeObservable, reaction, runInAction } from 'mobx'

import makeQuery from 'api/makeQuery'

class FamilyHistory {
  constructor(root, valuesObj) {
    this.root = root
    this.relationship = ''
    this.familyHistoryId = valuesObj?.familyHistoryId ?? ''
    this.currentConditions = valuesObj?.conditions ?? []
    this.conditions = []
    this.possibleConditions = []
    makeObservable(this, {
      relationship: true,
      conditions: true,
      currentConditions: true,
      setRelationship: true,
      isListDisabled: true,
      addCondition: true,
      addTypeCondition: true,
      removeCondition: true,
      removeTypeCondition: true,
      possibleConditions: true,
      submit: true,
    })
    this.disposeRelationshipReaction = reaction(
      () => this.relationship,
      (relationship) => {
        this.conditions = this.currentConditions.filter((condition) => condition.relationship === relationship)
      },
    )
  }

  destroy() {
    this.disposeRelationshipReaction()
  }

  async resetPossibleConditions() {
    const possibleConditions = await makeQuery('getPossibleConditions')
    runInAction(() => {
      this.possibleConditions = possibleConditions
    })
  }

  async submit() {
    // Find all conditions for relationships that are not related to the one being modified
    const conditionsToSubmit = this.currentConditions.filter(
      (condition) => condition.relationship !== this.relationship,
    )
    // Merge new conditions with conditions that are not related to the relationship being modified
    conditionsToSubmit.push(...this.conditions)
    const params = this.familyHistoryId
      ? {
          conditions: conditionsToSubmit,
          memberId: this.root.member.memberId,
        }
      : {
          familyHistory: {
            conditions: conditionsToSubmit,
            memberId: this.root.member.memberId,
          },
        }
    const fetchType = this.familyHistoryId ? 'putFamilyHistory' : 'postFamilyHistory'
    const response = await makeQuery(fetchType, params)

    runInAction(() => {
      this.currentConditions = response.conditions
      this.familyHistoryId = response.familyHistoryId
    })
  }

  setRelationship(relationship) {
    this.relationship = relationship
  }

  addCondition(condition) {
    this.conditions.push({
      condition: condition.condition,
      conditionId: condition.conditionId,
      name: condition.name,
      relationship: this.relationship,
    })
  }

  addTypeCondition(condition, type) {
    this.conditions.push({
      condition: condition.condition,
      conditionId: condition.conditionId,
      name: type.name,
      relationship: this.relationship,
    })
  }

  removeCondition(condition) {
    const index = this.conditions.findIndex((_condition) => _condition.name === condition.name)
    if (index !== -1) this.conditions.splice(index, 1)
  }

  removeTypeCondition(type) {
    const index = this.conditions.findIndex((condition) => condition.name === type.name)
    if (index !== -1) this.conditions.splice(index, 1)
  }

  async deleteFamilyMember(relationship) {
    const conditionsWithFamilyMemberRemoved = this.currentConditions.filter(
      (condition) => condition.relationship !== relationship,
    )
    const response = await makeQuery('putFamilyHistory', {
      conditions: conditionsWithFamilyMemberRemoved,
      memberId: this.root.member.memberId,
    })
    runInAction(() => {
      this.currentConditions = response.conditions
    })
  }

  get isListDisabled() {
    return !this.relationship
  }
}

export default FamilyHistory
