import { object, string, array } from 'yup'

import { FamilyConditionSchema } from 'schemas/FamilyHistory'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  conditions: array().of(FamilyConditionSchema).required(),
})

export const url = ({ memberId }) => `/v1/history/family/${memberId}`

export const getBody = ({ conditions }) => ({ conditions })
