import * as Yup from 'yup'
import { relationships } from '../constants/familyHistory'

export const FamilyConditionSchema = Yup.object().shape({
  condition: Yup.string().required(),
  conditionId: Yup.string().required(),
  name: Yup.string().required(),
  relationship: Yup.string().oneOf(relationships).required(),
})

export const FamilyHistorySchema = Yup.object().shape({
  conditions: Yup.array().of(FamilyConditionSchema).required().min(1),
  relationship: Yup.string().oneOf(relationships).required(),
})

export default FamilyHistorySchema
