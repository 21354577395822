import { object, array, number, string } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  lt: object(),
  gt: object(),
  filter: object().shape({
    member_id: string(),
    status: array(),
    provider_id: string().optional(),
    visit_type: string().optional(),
    start_date: string().optional(),
  }),
  sort: object(),
  page: object().shape({
    skip: number(),
    limit: number(),
  }),
})

export const url = ({ filter, gt, lt, sort, page }) =>
  `/v1/visits/pending?${queryString.stringify({ filter, gt, lt, sort, page }, { indices: false })}`

export const shouldNotLogoutOn403Error = true
