import eachHourOfInterval from 'date-fns/eachHourOfInterval'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import addMinutes from 'date-fns/addMinutes'

const MS_IN_ONE_MIN = 60000

// Scheduled visit shown as "active" if < 30 min left until scheduled time
export const BEFOREHAND_DURATION = MS_IN_ONE_MIN * 10

// Scheduled visit shown as "active" if < 30 min left until scheduled time
export const VISIT_PAGE_BLOCK_TIMER = MS_IN_ONE_MIN * 30

export const DAY_HOURS = eachHourOfInterval({
  start: startOfDay(new Date(`01/01/2000`)),
  end: endOfDay(new Date(`01/01/2000`)),
}).flatMap((hour) => [hour, addMinutes(hour, 15), addMinutes(hour, 30), addMinutes(hour, 45)])
