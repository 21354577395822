// TODO: handle errors for wrong url (405)
const parseBackendErrors = (err, message) => {
  if (err !== null) {
    switch (typeof err) {
      case 'object':
        if (err instanceof Error) {
          return err.message || message
        } else if (Array.isArray(err.errors) && err.errors.length) {
          /* both msg and message, because auth service has different error handling */
          const [firstError] = err.errors
          if (firstError.detail) {
            switch (typeof firstError.detail) {
              case 'object':
                return firstError.detail.message || 'Unknown error detail format'
              default:
                return firstError.detail
            }
          }
          return firstError.msg
        } else if (err.status) {
          return `Request failed with status code ${err.status}.`
        } else {
          return err.toString()
        }
      case 'string':
      case 'number':
        return err
      default:
        return ''
    }
  } else {
    return message
  }
}
export default parseBackendErrors
