import { object, string, ref } from 'yup'
import PasswordSchema from './Password'

export const ExpiredPasswordSchema = object()
  .shape({
    oldPassword: string() // invisible to user
      .required('Password Required'),
  })
  .concat(PasswordSchema)

export const ExpiredPasswordSchemaWithConfirm = object()
  .shape({
    confirmPassword: string()
      .min(6, 'Too Short')
      .oneOf([ref('password'), null], 'Passwords must match!'),
  })
  .concat(PasswordSchema)
