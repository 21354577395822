import queryString from 'qs'
import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  include: object().shape({
    member: string(),
  }),
})

export const url = ({ visitId, include }) =>
  `/v1/visits/pending/${visitId}${queryString.stringify({ include }, { addQueryPrefix: true })}`
