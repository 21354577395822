import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx'
import memoize from 'lodash/memoize'
import makeQuery from 'api/makeQuery'
import { getMaxWeeksForFollowUp } from 'util/getMaxWeeksForFollowUp'
import VisitTypes from 'constants/visit-types'
import moment from 'moment'
import { API_PENDING_VISITS_FETCH_LIMIT } from 'constants/apiFetchLimits'

const OFFSET_NOW = new Date().getTimezoneOffset() + 60000
export const PSYCHIATRY_SCHEDULING_LIMIT = 30 // in days

const roundByDate = (ts: number) => {
  const date = new Date(ts)
  const OFFSET = date.getTimezoneOffset() * 60000
  ts -= OFFSET
  ts -= ts % (24 * 3600 * 1000)
  return ts + OFFSET_NOW
}

class Availability {
  list: any = []
  visitsAffectingPsychLimits: any = []
  timeStamps = new Set()
  roundedTimeStamps = new Set()
  visit: any
  destroy: any

  constructor(visit: any) {
    makeAutoObservable<Availability>(this, {
      has: false,
      hasDate: false,
      withDate: false,
    })
    this.visit = visit
    this.destroy = onBecomeObserved(this, 'list', () => this.refetchAvailability())
  }

  get empty() {
    return !this.list.length
  }

  // Fetch any of the member's psych visits that will affect availability due to the 30 day psych limit
  async fetchVisitsAffectingPsychLimits() {
    const result = await makeQuery('getPendingVisits', {
      page: {
        skip: 0,
        limit: API_PENDING_VISITS_FETCH_LIMIT,
      },
      sort: {
        scheduled_at: -1,
      },
      filter: {
        status: ['pending', 'pending-confirmation', 'completed', 'in-progress', 'in-review'],
        member_id: this.visit.memberId,
        visit_type: VisitTypes.psychiatry,
        // startDate creates a gte filter against scheduled_at
        start_date: moment().subtract(PSYCHIATRY_SCHEDULING_LIMIT, 'days').format(),
      },
    })

    runInAction(() => {
      this.visitsAffectingPsychLimits = result?.paginated ?? []
    })
  }

  get scheduledVisitsAffectingPsychLimits() {
    return this.visitsAffectingPsychLimits
  }

  async refetchAvailability() {
    const endWeeks = getMaxWeeksForFollowUp({
      scheduledType: this.visit.scheduledType,
      visitType: this.visit.visitType,
    })

    // TODO: Upgrade Yup version to remove error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result = await makeQuery('getProviderAvailability', {
      providerId: this.visit.providerId,
      memberId: this.visit.memberId,
      visitType: this.visit.visitType,
      endWeeks,
    })

    runInAction(() => {
      const dates = result.map((d) => new Date(d))
      this.list.replace(dates)

      this.timeStamps.clear()
      this.roundedTimeStamps.clear()

      for (const d of dates) {
        const ts = d.getTime()
        this.timeStamps.add(ts)
        this.roundedTimeStamps.add(roundByDate(ts))
      }

      // TODO: Fix error saying that withDate can be undefined
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.withDate.cache.clear()
    })
  }

  hasDate(date: Date) {
    if (date instanceof Date) {
      const ts = date.getTime()
      return this.roundedTimeStamps.has(roundByDate(ts))
    }
    throw new Error('date must be instanceOf Date')
  }

  has(date: Date) {
    if (date instanceof Date) {
      return this.timeStamps.has(+date)
    }
    throw new Error('date must be instanceOf Date')
  }

  withDate = memoize(
    (date) => {
      if (!date) {
        return this.list
      }

      const finalDate = date instanceof Date ? date : new Date(date)
      const ts = roundByDate(+finalDate)
      return this.list.filter((d: any) => roundByDate(+d) === ts)
    },
    (date) => +date,
  )
}

export default Availability
