import { InferType, number, object, string } from 'yup'

export const Schema = object().shape({
  patientId: number(),
  prescriptionId: number(),
  dateWritten: string(),
  errorDateTimeStamp: string(),
  errorDetails: string(),
})

export { provider as baseURL } from 'constants/apiBaseUrls'

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { data: 'OK' }

export const url = () => `/v2/transmission-errors/acknowledge`

export const getBody = ({ prescriptionId, dateWritten, errorDateTimeStamp, errorDetails, patientId }) => ({
  prescriptionId,
  dateWritten,
  errorDateTimeStamp,
  errorDetails,
  patientId,
})
