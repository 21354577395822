export { ChangeRxRequestQuerySchema as Schema } from 'schemas/ChangeRxRequest'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const url = ({ rxChangeRequestId }) => `/v1/prescription-changes/${rxChangeRequestId}/approve`

export const getBody = ({
  providerId,
  prescriptionExternalId,
  newPrescription,
  newPrescriptionPatientId,
  selectedMedicationId,
  priorAuthNumber,
  prescriberLicenseInactive,
  prescriberAuthorizationData,
}) => ({
  providerId,
  prescriptionExternalId,
  newPrescription,
  newPrescriptionPatientId,
  selectedMedicationId,
  priorAuthNumber,
  prescriberLicenseInactive,
  prescriberAuthorizationData,
})
