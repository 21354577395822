import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  externalId: string().notRequired().nullable(),
  status: string().required(),
  visitId: string().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { test: 'test' }

export const url = ({ visitId }) => `/v2/visits/${visitId}/conferences/callback`

export const getBody = ({ externalId, status }: Request) => ({ externalId, status })
