import { object, string, boolean } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  snooze: string().nullable(),
  sms: boolean().required(),
  email: boolean().required(),
})

export const url = ({ providerId }) => `/v1/providers/${providerId}/notifications`

export const getBody = ({ snooze, sms, email }) => ({
  notifications: { snooze, sms, email },
})
