import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  providerId: string().required(),
})

export const url = ({ visitId, providerId }) => `/v1/visit/${visitId}/provider/${providerId}`
