import { observable, makeObservable, action } from 'mobx'
import mapValues from 'lodash/mapValues'

class Modals {
  list = []
  optionsMap = new Map()
  i = 0

  constructor() {
    makeObservable(this, {
      list: observable.shallow,
      mergeOptions: action,
      open: action,
      close: action,
      closeAll: action,
    })
  }

  mergeOptions(id, newOptions) {
    const options = this.optionsMap.get(id)
    if (options) {
      Object.assign(options, newOptions)
    }
  }

  some(fn) {
    return this.list.some(fn)
  }

  open(type, options = {}) {
    const observableOptions = observable(
      options,
      mapValues(options, () => observable.ref),
    )
    const id = this.i++
    this.list.push({
      id,
      type,
      options: observableOptions,
    })
    this.optionsMap.set(id, observableOptions)
    return id
  }

  close(id) {
    if (this.optionsMap.delete(id)) {
      const idx = this.list.findIndex((m) => m.id === id)
      this.list.splice(idx, 1)
    }
  }

  closeAll() {
    this.optionsMap.clear()
    this.list = []
  }
}

export default new Modals()
