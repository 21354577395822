import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  visitId: string().required(),
})

export const url = ({ providerId, visitId }) => `/v2/providers/${providerId}/visits/${visitId}/video/token`
