import * as Yup from 'yup'
const AllergySchema = Yup.object().shape({
  memberId: Yup.string().notRequired(),
  current: Yup.boolean().required(),
  allergyType: Yup.string().oneOf(['drug', 'food']).required(),
  allergyDescription: Yup.string().label('Allergy').required(),
  reaction: Yup.string().label('Reaction').required(),
})

export default AllergySchema
