import { phoneInputFormat } from 'util/formatters'
import { LANGUAGES } from 'constants/languages'
import { GENDERS_MAP, DOSESPOT_GENDERS_MAP, GENDER_SELF_SELECTED_LABEL } from 'constants/genders'

const upsertByKey = (arr, newObj, k) => {
  const existingObj = arr.find((el) => el[k] === newObj[k])
  if (existingObj) {
    Object.assign(existingObj, newObj)
  } else {
    arr.push(newObj)
  }
  return arr
}

export const getPreferred = (arr) => (arr.length ? arr.find(({ preferred }) => preferred) || arr.at(-1) : null)

export const getPhone = (phones) => {
  const targetPhone = getPreferred(phones)
  return targetPhone?.number ? phoneInputFormat(targetPhone.number) : '-'
}

export const getPhonesToSend = (phones, phoneNumber, phoneType) =>
  upsertByKey(
    phones.map((phone) => ({ ...phone, preferred: false })),
    {
      type: phoneType,
      number: phoneNumber,
      preferred: true,
    },
    'number',
  )

export const getAddressesToSend = (addresses, street1, street2, city, state, zip) =>
  upsertByKey(
    addresses.map((address) => ({ ...address, preferred: false })),
    { street1, street2, city, state, zip, country: 'US', preferred: true },
    'street1',
  )

/* Types:
basic - return as single string
extended - return obj with street and state fields */
export const getFullAddress = (addresses, type) => {
  const targetAddress = getPreferred(addresses)
  if (!targetAddress) {
    return '-'
  }
  const { street1 = '', street2 = '', city = '', state = '', zip = '' } = targetAddress
  if (type === 'basic') {
    const fullAddress = `${street1}, ${city}, ${state} ${zip}`
    return fullAddress.length > 5 ? fullAddress : '-'
  }
  if (type === 'extended') {
    const formattedState = `${city}, ${state} ${zip}`
    const formattedStreet = `${street1} ${street2 || ''}`.trim()
    return {
      street: formattedStreet.length ? formattedStreet : '-',
      state: formattedState.length > 3 ? formattedState : '-',
    }
  }
}

export const getAddressStreet = (clinician) => {
  const address1 = clinician.address1 || ''
  const address2 = clinician.address2 || ''
  return `${address1} ${address2}`.trim() || '-'
}

export const getAddressState = (address) => {
  const { city, state, zipCode = '' } = address

  if (city && state) {
    return `${city}, ${state} ${zipCode}`
  }
  return '-'
}

export const getSubstitutions = (flag) => (flag ? 'Allowed' : 'Not Allowed')

export const getUnit = (rx) => {
  const { unit, dispenseUnit, dispenseUnitDescription, strength } = rx
  if (dispenseUnitDescription) return `${strength} ${dispenseUnitDescription}`
  if (!dispenseUnit || !dispenseUnit.singularOrPlural) return unit
  return `${unit} ${dispenseUnit.singularOrPlural}`
}

export const getGender = (gender) => GENDERS_MAP[gender] || GENDER_SELF_SELECTED_LABEL

export const getDosespotGender = (gender) => DOSESPOT_GENDERS_MAP[gender] || GENDER_SELF_SELECTED_LABEL

export const getHeight = (heightFt, heightIn) =>
  [heightFt, heightIn, heightFt ? heightFt * 12 + heightIn : 0]
    .map((v, i) => (v ? (i == 2 ? `(${v} in)` : `${v} ${i ? 'in' : 'ft'}`) : ''))
    .join(' ') || '-'

export const getWeight = (weightLbs) => (weightLbs ? `${weightLbs} lbs` : '-')

export const getNotificationStatus = (flag) => (flag ? 'Enabled' : 'Disabled')

export const getJoinedFields = (arr) => arr?.join(', ') || ''

export const filterNoneFromField = (arr) => arr?.filter((i) => i.toLowerCase() !== 'none')

export const getLanguages = (arr) => {
  const names = arr.map(({ name }) => name)
  return getJoinedFields(names)
}

export const getLanguagesToSend = (languages) =>
  languages.map((abbrev) => ({ name: LANGUAGES[abbrev] || abbrev, abbrev }))

export const getLabelFromOptions = (options, value) => {
  const option = options.find((option) => option.code === Number(value))
  return option?.reason ?? ''
}
