import { makeStyles } from '@material-ui/core/styles'
import {
  MODALS_WIDTH_MOBILE_REDUCTION,
  MODALS_WIDTH_SMALL_MOBILE_REDUCTION,
  MODALS_ADD_ADDENDUM_FULL_WIDTH,
} from 'constants/breakpoints'

const useStyles = makeStyles((theme) => ({
  patientSummaryRoot: {
    width: '69.27em',
  },
  modalWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    height: '3em',
    color: theme.palette.error.secondary,
    borderColor: theme.palette.error.secondary,
    '&:hover': {
      borderColor: theme.palette.error.secondary,
    },
  },
  setAvailabilityRoot: {
    [theme.breakpoints.down(MODALS_WIDTH_MOBILE_REDUCTION)]: {
      minWidth: 'calc(100% - 30px)',
    },
    [theme.breakpoints.down(MODALS_WIDTH_SMALL_MOBILE_REDUCTION)]: {
      minWidth: 'calc(100% - 20px)',
    },
  },
  blackoutDatesRoot: {
    [theme.breakpoints.down(MODALS_WIDTH_SMALL_MOBILE_REDUCTION)]: {
      minWidth: 'calc(100% - 20px)',
    },
  },
  addAddendumRoot: {
    [theme.breakpoints.down(MODALS_ADD_ADDENDUM_FULL_WIDTH)]: {
      minWidth: 'calc(100% - 30px)',
    },
  },
}))

export default useStyles
