import { makeAutoObservable } from 'mobx'
import pick from 'util/pick'
import makeQuery from 'api/makeQuery'
import debounce from 'lodash/debounce'

class Dxs {
  syncDebounced = debounce(async () => {
    const mergeStamp = ++this.putQueriesCounter
    const visitRecord = await makeQuery('putVisitDx', {
      visitId: this.visit.visitId,
      dxs: this.list.map((dx) => pick(dx, ['code', 'description'])),
    })
    if (mergeStamp === this.putQueriesCounter) {
      this.visit.merge(visitRecord)
    }
  })

  constructor(visit, initialValues) {
    /* Array of: { code, description, id } */
    this.list = initialValues || []

    makeAutoObservable(this, {
      syncDebounced: false,
      destroy: false,
      map: false,
    })

    this.visit = visit

    /* if 1st query finishes after 2nd - old data must not be merged */
    this.putQueriesCounter = 0
  }

  map(cb) {
    return this.list.map(cb)
  }

  get length() {
    return this.list.length
  }

  replace(arr) {
    this.list.replace(arr || [])
  }

  destroy() {
    this.syncDebounced.flush()
  }

  delete(dxId) {
    const idx = this.list.findIndex((dx) => dx.id === dxId)
    if (idx !== -1) {
      this.list.splice(idx, 1)
      this.syncDebounced()
    }
  }

  add(code, description) {
    if (!this.list.some((dx) => dx.code === code && dx.description === description)) {
      this.list.push({
        code,
        description,
        /*
                Must not be used as key, code is already unique.
                Needed only for delete.
                Comes from server afer putVisitDx query.
            */
        id: '',
      })
      this.syncDebounced()
    }
  }
}

export default Dxs
