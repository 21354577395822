import { makeObservable, computed, comparer, reaction } from 'mobx'
import Record from 'models/Record'

const reduceAnswersToString = (acc, answer) => {
  if (!acc) {
    return answer.label
  }
  return `${acc}, ${answer.label}`
}

class Question extends Record {
  static INITIAL_FIELDS = {
    instruction: '',
    tooltip: '',
    isSendable: false,
    id: '',
    multipleChoice: false,
    order: 0,
    prompt: '',
    questionChoices: [],
    questionType: '',
    required: false,
    uniqueId: '',
    prereq: '',
  }

  get parsedPrereq() {
    return this.prereq.split('|').flatMap((v) => (v ? [v.split('.').map((v) => +v)] : []))
  }

  get disabled() {
    return (
      !!this.parsedPrereq.length &&
      this.parsedPrereq.every(([uniqueId, v]) => !this.allQuestions.byUniqueId[uniqueId]?.answers.includes(v))
    )
  }

  get printableAnwser() {
    if (!this.answers.length) return ''
    switch (this.questionType) {
      case 'text':
        return this.answers[0] || ''
      case 'radio':
        return this.questionChoices.find((questionChoice) => questionChoice.value === this.answers[0])?.label || ''
      case 'checkbox':
        return this.questionChoices
          .filter((questionChoice) => this.answers.includes(questionChoice.value))
          .reduce(reduceAnswersToString, '')
      default:
        return ''
    }
  }

  get answered() {
    return !!this.answers.length && this.answers[0] !== ''
  }

  get missingAnswer() {
    return this.required && !this.disabled && !this.answered
  }

  /* previously was just 'length === 10' hardcoded inside component */
  get shouldDisplayToggleButtonGroup() {
    const { questionChoices: q } = this
    return q.length > 1 && q.length < 15 && q.every((choice) => !isNaN(choice.label))
  }

  constructor(allQuestions, question, initialAnswers) {
    super(question)

    this.answers = initialAnswers || []
    this.allQuestions = allQuestions
    this.disposeClearInput = reaction(
      () => this.disabled,
      (isDisabled) => {
        if (isDisabled && this.answers.length) this.answers = []
      },
    )

    makeObservable(this, {
      answers: true,
      setValue: true,
      answered: true,
      disabled: true,
      printableAnwser: true,
      shouldDisplayToggleButtonGroup: true,
      parsedPrereq: computed({ equals: comparer.structural }),
    })
  }

  destroy() {
    this.disposeClearInput()
  }

  setValue(value, operation = 'replace') {
    if (operation === 'replace') {
      this.answers.replace([value])
    } else {
      const idx = this.answers.indexOf(value)
      if (operation === 'add') {
        if (idx === -1) {
          this.answers.push(value)
        }
      } else if (idx !== -1) {
        this.answers.splice(idx, 1)
      }
    }
  }
}

export default Question
