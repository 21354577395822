import { contactReasonsOptionsArray } from 'constants/contactReasons'
import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  type: string().oneOf(contactReasonsOptionsArray).required(),
  details: string().required(),
})

export const url = () => `/v1/support-tickets`

export const getBody = ({ type, details }) => ({ type, details })
