import { object, string, array } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

const ChatMessageSchema = object().shape({
  senderId: string().label('Sender ID').required(),
  tagIds: array().of(string()),
  message: string().label('Message').trim().required(),
})

export const Schema = object().shape({
  chatId: string().label('Chat ID').required(),
  message: ChatMessageSchema,
})

export const getBody = ({ message }) => message

export const url = ({ chatId }) => `/v1/chats/${chatId}/messages`
