import { makeObservable } from 'mobx'
import zipObject from 'lodash/zipObject'

import Period from './Period'

const DAYS = [
  { shortName: 'm', fullName: 'Monday' },
  { shortName: 't', fullName: 'Tuesday' },
  { shortName: 'w', fullName: 'Wednesday' },
  { shortName: 'th', fullName: 'Thursday' },
  { shortName: 'f', fullName: 'Friday' },
  { shortName: 's', fullName: 'Saturday' },
  { shortName: 'su', fullName: 'Sunday' },
]
const DEFAULT_PERIOD = ['08:00', '09:00']

class AvailabilityCollection {
  days = []

  constructor(provider) {
    makeObservable(this, {
      days: true,
      addPeriod: true,
      deletePeriod: true,
      recalc: true,
      formattedDays: true,
      isAnyPeriodInvalid: true,
    })
    this.provider = provider
  }

  addPeriod = (name) => {
    const index = this.days.findIndex((day) => day.name === name)
    this.days[index].periods.push(new Period(this, name, DEFAULT_PERIOD))
  }

  deletePeriod = (name, id) => {
    const day = this.days.find((day) => day.name === name)
    if (day) {
      const periodIndex = day.periods.findIndex((period) => period.id === id)
      if (periodIndex !== -1) {
        day.periods.splice(periodIndex, 1)
      }
    }
  }

  recalc = () => {
    const availability = this.provider.schedule.availability
    this.days = DAYS.map(({ shortName, fullName }) => ({
      name: fullName,
      periods: availability?.[shortName].map((period) => new Period(this, fullName, period)) || [],
    }))
  }

  // Add destroy functionality later if needed
  // TODO: Do not make it an arrow function
  destroy = () => {}

  get formattedDays() {
    return zipObject(
      DAYS.map((day) => day.shortName.toUpperCase()),
      this.days.map((day) => day.periods.map((period) => period.pair)),
    )
  }

  get isAnyPeriodInvalid() {
    return this.days.some((day) => {
      return day.periods.some((period) => {
        return !period.isValid
      })
    })
  }
}

export default AvailabilityCollection
