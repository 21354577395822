import { object, string } from 'yup'

export { auth as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  password: string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
  resetToken: string().required(),
})

export const url = () => '/v1/reset'

export const getBody = ({ password, resetToken }) => ({ password, resetToken })
