export const getBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = function () {
      if (typeof reader.result === 'string') {
        const base64 = reader.result.replace(/^data:.+;base64,/, '')
        resolve(base64)
      } else {
        reject(new Error('Failed to convert blob to base64'))
      }
    }

    reader.onerror = function () {
      reject(new Error('Failed to read blob'))
    }

    reader.readAsDataURL(blob)
  })
}
