import { makeObservable } from 'mobx'
import mapValues from 'lodash/mapValues'
import makeQuery from 'api/makeQuery'
import Record from 'models/Record'

import { PendingSlot, TimeSlot as TimeSlotType, TimeSlotVisit } from 'types/Provider/Schedule'

import Schedule from '..'

export interface FilledTimeSlot extends TimeSlot {
  visit: TimeSlotVisit
}
interface TimeSlot extends TimeSlotType {}

class TimeSlot extends Record {
  static INITIAL_FIELDS: TimeSlotType = {
    slotId: '',
    permission: 'public',
    type: 'initial',
    startTime: '',
    startTimeHour: 0,
    startTimeMinute: 0,
    endTime: '',
    endTimeHour: 0,
    endTimeMinute: 0,
    visit: undefined,
    createdAt: '',
    updatedAt: '',
  }

  schedule: Schedule

  constructor(timeSlot: TimeSlotType, schedule: Schedule) {
    super(timeSlot)

    this.schedule = schedule

    makeObservable(this, {
      // @ts-ignore
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      editSlot: true,
      deleteSlot: true,
      visitRecord: true,
      visitDetails: true,
    })
  }

  get visitRecord() {
    return this.schedule.root.provider.visitQueue.getById(this.visit?.pendingVisitId)
  }

  get visitDetails() {
    const visit = this.visitRecord
    return {
      modality: visit.modality,
      memberFullName: visit.memberFullName,
      age: visit.age,
      reasonForVisit: visit.reasonForVisit,
      fullLocation: visit.fullLocation,
      scheduledAt: visit.scheduledAt,
    }
  }

  async editSlot(slot: PendingSlot) {
    const updatedSlot = await makeQuery('putScheduleSlot', {
      ...this.schedule.commonParams,
      slotId: this.slotId,
      slot,
    })
    this.merge(updatedSlot)
  }

  async deleteSlot() {
    const schedule = await makeQuery('deleteScheduleSlot', {
      ...this.schedule.commonParams,
      slotId: this.slotId,
    })
    this.schedule.mergeScheduleAndSlots(schedule)
  }
}

export default TimeSlot
