// Wrapper with styles for Modal actions

import PropTypes from 'prop-types'

import useStyles from './styles'

const ModalActions = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
ModalActions.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ModalActions
