import { object } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  filter: object(),
})

export const url = ({ filter }) =>
  `/v1/care-protocols/versions?${queryString.stringify({ filter }, { indices: false })}`
