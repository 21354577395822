import { InferType, object, string } from 'yup'

import { MedicalHistorySchema } from 'schemas/MedicalHistory'
import MedicalHistory from 'types/PrivateData/MedicalHistory'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medicalHistory: MedicalHistorySchema,
})

export type Request = InferType<typeof Schema>

export const url = ({ memberId }: Request) => `/v2/history/personal/${memberId}`

export const getBody = ({ medicalHistory }: Request) => ({ medicalHistory })

export type SuccessfulResponse = MedicalHistory
