import { object, string, number } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  rxChangeRequestId: number().required(),
  providerId: string().required(),
  reason: number().oneOf([4, 5, 6, 7, 8, 9, 14, 15, 16, 17, 18, 21, 22, 23]).required(),
})

export const url = ({ rxChangeRequestId }) => `/v1/prescription-changes/${rxChangeRequestId}/deny`

export const getBody = ({ providerId, reason }) => ({
  providerId,
  reason,
})
