import { makeObservable, action } from 'mobx'
import difference from 'lodash/difference'
import pick from 'util/pick'

const LOG_KEYS_DIFF = false
class Record {
  static INITIAL_FIELDS = {}

  initialize(valuesObj) {
    Object.assign(this, this.constructor.INITIAL_FIELDS, pick(valuesObj, this.ALLOWED_FIELDS))
    this.initializeTimestamp = Date.now()
  }

  destroy() {}

  merge(valuesObj) {
    if (valuesObj) {
      const allowedValuesObj = pick(valuesObj, this.ALLOWED_FIELDS)
      if (process.env.NODE_ENV !== 'production' && LOG_KEYS_DIFF) {
        const keysDiff = difference(Object.keys(valuesObj), Object.keys(allowedValuesObj))
        if (keysDiff.length) {
          console.log('Keys diff detected in merge: ', this.constructor.name, keysDiff, this)
        }
      }
      Object.assign(this, allowedValuesObj)
      this.initializeTimestamp = Date.now()
    }
  }

  constructor(valuesObj) {
    this.ALLOWED_FIELDS = Object.keys(this.constructor.INITIAL_FIELDS)
    this.initializeTimestamp = 0
    this.initialize(valuesObj)
    makeObservable(this, {
      initialize: action,
      merge: action,
    })
  }
}

export default Record
