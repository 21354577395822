import { object, string, number, bool, array, boolean } from 'yup'
import PhoneSchema from 'schemas/Phone'
import ProfileSchema from 'schemas/Profile'
import { getAddressSchema } from 'schemas/Address'
import { LicenseSchema } from 'schemas/License'
import { EducationSchema } from 'schemas/Education'
import { timezones } from 'constants/timezones'
import { modalities } from 'constants/modalities'

export const ProviderSchema = object().shape({
  providerId: string(),
  userId: string(),
  externalId: string(),
  rxVendorId: string(),
  vendorConfirmed: bool(),
  email: string().label('Email').email('Must be a valid email').required(),
  dob: string()
    .label('Date of Birth')
    .matches(/^(\d{4}-\d{2}-\d{2})$/, 'Must be a valid date, YYYY-MM-DD')
    .required(),
  timezone: string().label('Timezone').oneOf(timezones, 'Must be a valid timezone').required(),
  dea: string(),
  npi: string(),
  prefix: string().label('Salutation').required(),
  firstName: string().label('First Name').required(),
  middleInitial: string(),
  lastName: string().label('Last Name').required(),
  modalities: array().label('Modalities').of(string().oneOf(modalities)).required(),
  priority: number()
    .label('Priority')
    .min(1, 'Priority must be 1 or more')
    .max(10, 'Priority must be 10 or less')
    .required(),
  addresses: array().label('Address').of(getAddressSchema(false)).min(1, 'At least one Address is required').required(),
  phones: array().label('Phones').of(PhoneSchema).min(1, 'At least one Phone is required').required(),
  licenses: array().label('Licenses').of(LicenseSchema),
  education: array().of(EducationSchema),
  paymentType: string().label('Payment Type').required(),
  profile: ProfileSchema,
})
export const ProviderFormSchema = object().shape({
  /* 
    disabled fields are not validated, so this should be hardcoded in forms
    email: string().label('Email').email('Must be a valid email').required(),
  */
  street1: string()
    .label('Street 1')
    .trim('Street 1 should have no leading or trailing spaces')
    .min(2, 'Street 1 too Short')
    .max(35, 'Street 1 too Long')
    .required(),
  street2: string()
    .label('Street 2')
    .trim('Street 2 should have no leading or trailing spaces')
    .max(35, 'Street 2 too Long')
    .nullable()
    .notRequired(),
  city: string()
    .label('City')
    .trim('City should have no leading or trailing spaces')
    .min(2, 'City is too Short')
    .max(35, 'City is too Long')
    .required(),
  zip: string()
    .label('Zip Code')
    .matches(/\d{5}/, 'Zip Code must be 5 digits')
    .min(5, 'Zip Code is too Short')
    .max(5, 'Zip Code is too Long')
    .required(),
  state: string().label('State').min(2, 'State is too Short').max(2, 'State is too Long').required(),
  phoneNumber: string()
    .label('Phone Number')
    .matches(/(^\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}$)|(^\d{10}$)/, 'Phone number must be a 10 digit number')
    .required(),
  phoneType: string()
    .label('Phone Type')
    .oneOf(['mobile', 'home', 'office'], 'Must be one of Mobile, Home, or Office')
    .required(),
  acceptSmsNotifications: boolean(),
  acceptEmailNotifications: boolean(),
  profile: ProfileSchema,
})
