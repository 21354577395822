import { boolean, object, string } from 'yup'

export const CrisisEscalationSchema = object().shape({
  phoneNumber: string()
    .matches(/^(\d{10})$/, 'Phone number must be a 10 digit number')
    .required(),
  reason: string().required(),
  reasonOther: string().when('reason', {
    is: 'other',
    then: string().required(),
    otherwise: string().strip(),
  }),
  notes: string(),
  acknowledge: boolean().strip(),
})
