import { object, string } from 'yup'

import AllergySchema from 'schemas/Allergy'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  allergy: AllergySchema.required(),
})

export const url = ({ memberId }) => `/v1/allergies/member/${memberId}`

export const getBody = ({ allergy }) => ({ allergy })
