import { object, string } from 'yup'

import AllergySchema from 'schemas/Allergy'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  allergyId: string().required(),
  allergy: AllergySchema.required(),
})

export const url = ({ allergyId }) => `/v1/allergies/${allergyId}`

export const getBody = ({ allergy }) => ({ allergy })
