import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  memberId: string().required(),
})

export const url = ({ providerId, memberId }) => `/v1/providers/${providerId}/member/${memberId}`
