import { lazy, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { configure as mobxConfigure } from 'mobx'
import { ThemeProvider } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ToastContainer, Slide } from 'react-toastify'
import CssBaseline from '@material-ui/core/CssBaseline'
import Modals from 'singletons/Modals'
import ModalsContainer from '../ModalsContainer'
import ErrorCatcher from '../ErrorCatcher'
import TopProgressBar from 'components/TopProgressBar'
import theme from 'theming/base'
import FlagProvider from 'components/FeatureFlag/FlagProvider'
import FlagSyncer from 'components/FeatureFlag/FlagSyncer'

import 'css/general.css'
import 'react-toastify/dist/ReactToastify.css'
import 'css/toastify_overrides.css'

const RouterTree = lazy(() => import('./RouterTree'))

if (process.env.NODE_ENV !== 'production') {
  mobxConfigure({
    disableErrorBoundaries: true,
  })
}

const HiddenModalOpener = () => (
  <div
    onClick={() => Modals.open('hidstgs')}
    style={{
      position: 'fixed',
      bottom: '1em',
      left: '1em',
      width: '1em',
      height: '1em',
      background: 'red',
      zIndex: 100000,
    }}
  />
)

const App = () => (
  <FlagProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {process.env.REACT_APP_ENVIRONMENT !== 'production' ? <HiddenModalOpener /> : null}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <ErrorCatcher>
            <Suspense fallback="Loading app...">
              <RouterTree />
            </Suspense>
            <ModalsContainer />
            <ToastContainer position="bottom-left" transition={Slide} />
          </ErrorCatcher>
        </Router>
      </MuiPickersUtilsProvider>
      <TopProgressBar />
      <FlagSyncer />
    </ThemeProvider>
  </FlagProvider>
)

export default App
