import { makeAutoObservable } from 'mobx'
import format from 'date-fns/format'
import endOfDay from 'date-fns/endOfDay'
import parse from 'date-fns/parse'
import RESTRICTIONS_LIST from 'constants/restrictionsList'

class Restriction {
  constructor(visit, initialValues) {
    /* Array of strings: with 3 default possible values:
    "PE/Exercise", "Lifting > 5lbs", "Travel", which mapped into checkboxes,
    and another possible random value "blabla", which mapped into input
    */
    this.list = initialValues?.restrictions || []
    this.resumeDate = initialValues?.resumeDate || ''

    makeAutoObservable(this)

    this.visit = visit
  }

  get resumeDateAdopted() {
    return this.resumeDate && endOfDay(parse(this.resumeDate, 'y-MM-dd', new Date()))
  }

  updateRestrictions(restrictions) {
    this.list.replace(restrictions || [])
  }

  addRestriction(restriction) {
    this.list.push(restriction)
  }

  removeRestriction(restriction) {
    const idx = this.list.indexOf(restriction)
    if (idx !== -1) {
      this.list.splice(idx, 1)
    }
  }

  toggleRestriction(restriction, flag) {
    if (flag) {
      this.addRestriction(restriction)
    } else {
      this.removeRestriction(restriction)
    }
  }

  setOtherRestriction(newRestriction) {
    const { otherRestrictionIndex } = this
    if (otherRestrictionIndex !== -1) {
      if (newRestriction) {
        this.list[otherRestrictionIndex] = newRestriction
      } else {
        this.list.splice(otherRestrictionIndex, 1)
      }
    } else if (newRestriction && !RESTRICTIONS_LIST.includes(newRestriction)) {
      this.addRestriction(newRestriction)
    }
  }

  updateResumeDate(date) {
    if (date) {
      if (date instanceof Date) {
        this.resumeDate = format(date, 'y-MM-dd')
      } else if (typeof date === 'string') {
        this.resumeDate = date
      } else {
        throw new Error(`Wrong resume date format. Got: ${date}`)
      }
    } else {
      this.resumeDate = ''
    }
  }

  // Find default restrictions
  get workSchoolRestrictions() {
    return this.list.filter((restriction) => RESTRICTIONS_LIST.includes(restriction))
  }

  get otherRestrictionIndex() {
    return this.list.findIndex((restriction) => !RESTRICTIONS_LIST.includes(restriction))
  }

  // Find non-default restriction
  get otherRestriction() {
    const { otherRestrictionIndex } = this
    return otherRestrictionIndex !== -1 ? this.list[otherRestrictionIndex] : ''
  }
}

export default Restriction
