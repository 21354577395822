import { InferType } from 'yup'

import { default as PostRxSchema } from 'schemas/PostRx'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = PostRxSchema

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = any //TODO type response

export const url = ({ visitId }) => `/v1/visit/${visitId}/rx`

export const getBody = ({ rx }) => ({ rx })
