import { MedicationDetailsData } from 'types/Medications'
import { InferType, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  dispensableDrugId: string(),
  filter: object({ name: string() }),
})

export type Request = InferType<typeof Schema>
export type SuccessfulResponse = MedicationDetailsData

export const url = ({ dispensableDrugId }: Request) => `/v2/medications/${dispensableDrugId}`
