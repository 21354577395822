import { computed, makeAutoObservable, observable, runInAction } from 'mobx'
import videoConstraints from 'util/videoConstraints'
import AudioVisualizer from './AudioVisualizer'
import { CallInstance, SelectedDeviceSettings } from 'types/CallInstance'
import { IS_SAFARI } from 'util/browsers'
import User from 'singletons/User'

class DeviceSettings {
  callInstance: CallInstance | null = null
  availableCameras = []
  availableMicrophones = []
  availableSpeakers = []
  selectedCameraId = ''
  selectedMicrophoneId = ''
  selectedSpeakerId = ''
  hasSelectedVideoBackground = true
  cameraPreviewRef = null
  microphonePreviewTrack = null
  microphoneVisualizer = new AudioVisualizer()
  microphonePermissionStatus: PermissionStatus
  safariMicrophonePermissionsPollingInterval: ReturnType<typeof setInterval>

  get storedMicrophoneId() {
    return localStorage.getItem('microphoneDeviceId')
  }

  get storedCameraId() {
    return localStorage.getItem('cameraDeviceId')
  }

  get storedSpeakerId() {
    return localStorage.getItem('speakerDeviceId')
  }

  get storedVideoBackground() {
    return localStorage.getItem('videoBackground') !== 'false'
  }

  constructor(callInstance) {
    this.callInstance = callInstance
    makeAutoObservable(this, {
      callInstance: observable.ref,
      isMicrophonesReady: computed,
      isSpeakersReady: computed,
      isCamerasReady: computed,
    })
  }

  setCameraPreviewRef(ref) {
    this.cameraPreviewRef = ref
  }

  setSelectedCameraId(deviceId) {
    this.selectedCameraId = deviceId
  }

  setSelectedSpeakerId(deviceId) {
    this.selectedSpeakerId = deviceId
  }

  setSelectedMicrophoneId(deviceId) {
    this.selectedMicrophoneId = deviceId
  }

  setSelectedVideoBackground(hasImage = false) {
    this.hasSelectedVideoBackground = hasImage
  }

  setMicrophonePreviewTrack(track) {
    this.microphonePreviewTrack = track
  }

  stopCameraPreviewTracks() {
    const cameraStream = this.cameraPreviewRef?.current?.srcObject
    if (cameraStream)
      cameraStream.getTracks().forEach((track) => {
        track.stop()
      })
  }

  stopMicrophonePreviewTrack = () => {
    if (this.microphonePreviewTrack) {
      this.microphonePreviewTrack.stop()
    }
  }

  changeCameraPreview(deviceId) {
    this.setSelectedCameraId(deviceId)
    this.stopCameraPreviewTracks()

    const newVideo = this.cameraPreviewRef?.current
    if (newVideo) {
      navigator.mediaDevices.getUserMedia({ video: videoConstraints(deviceId) }).then((stream) => {
        newVideo.srcObject = stream
      })
    }
  }

  async changeMicrophonePreview(deviceId) {
    this.setSelectedMicrophoneId(deviceId)
    this.stopMicrophonePreviewTrack()
    this.microphoneVisualizer.stop()
    this.microphoneVisualizer = new AudioVisualizer()
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId } })
    const audioTrack = audioStream.getAudioTracks()[0]
    this.setMicrophonePreviewTrack(audioTrack)
    this.microphoneVisualizer.start(audioTrack)
  }

  async changeSpeakerPreview(deviceId) {
    this.setSelectedSpeakerId(deviceId)
  }

  async fetchAvailableDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const camerasFound = devices.filter((device) => device.kind === 'videoinput')
    const microphonesFound = devices.filter((device) => device.kind === 'audioinput')
    const speakersFound = devices.filter((device) => device.kind === 'audiooutput')
    this.setInitialVideoBackground()
    this.setInitialCameraPreview(camerasFound)
    this.setInitialMicrophonePreview(microphonesFound)
    this.setInitialSpeakerPreview(speakersFound)

    runInAction(() => {
      this.availableCameras = camerasFound
      this.availableMicrophones = microphonesFound
      this.availableSpeakers = speakersFound
    })
  }

  setInitialMicrophonePreview(microphonesFound) {
    const savedMicrophoneDeviceId = localStorage.getItem('microphoneDeviceId')
    if (savedMicrophoneDeviceId) {
      this.changeMicrophonePreview(savedMicrophoneDeviceId)
    } else if (microphonesFound.length) {
      this.changeMicrophonePreview(microphonesFound[0].deviceId)
    }
  }

  setInitialVideoBackground() {
    const hasBackground = this.storedVideoBackground
    this.setSelectedVideoBackground(hasBackground)
  }

  setInitialCameraPreview(camerasFound) {
    const savedVideoDeviceId = localStorage.getItem('cameraDeviceId')
    if (savedVideoDeviceId) {
      this.changeCameraPreview(savedVideoDeviceId)
    } else if (camerasFound.length) {
      this.changeCameraPreview(camerasFound[0].deviceId)
    }
  }

  setInitialSpeakerPreview(speakersFound) {
    const savedSpeakerDeviceId = localStorage.getItem('speakerDeviceId')
    if (savedSpeakerDeviceId) {
      this.changeSpeakerPreview(savedSpeakerDeviceId)
    } else if (speakersFound.length) {
      this.changeSpeakerPreview(speakersFound[0].deviceId)
    }
  }

  async getMicrophonePermissionStatus() {
    return await navigator.permissions?.query({ name: 'microphone' as PermissionName })
  }

  startSafariMicrophonePermissionPolling() {
    if (IS_SAFARI && !this.safariMicrophonePermissionsPollingInterval) {
      this.safariMicrophonePermissionsPollingInterval = setInterval(async () => {
        console.log('tick')
        if (!this.isMicrophonesReady) {
          await this.fetchAvailableDevices()
        } else {
          this.stopSafariMicrophonePermissionPolling()
        }
      }, 1000)
    }
  }

  stopSafariMicrophonePermissionPolling() {
    if (this.safariMicrophonePermissionsPollingInterval) {
      clearInterval(this.safariMicrophonePermissionsPollingInterval)
      this.safariMicrophonePermissionsPollingInterval = null
    }
  }

  async checkMicrophonePermissions() {
    const microphonePermissionStatus = await this.getMicrophonePermissionStatus()
    this.startSafariMicrophonePermissionPolling()

    runInAction(() => {
      this.fetchAvailableDevices()
      microphonePermissionStatus.onchange = () => this.fetchAvailableDevices()
    })
  }

  unsubscribeFromMicrophonePermissionsChange() {
    if (this.microphonePermissionStatus) {
      this.microphonePermissionStatus.onchange = null
    }
    this.stopSafariMicrophonePermissionPolling()
  }

  initializeDevices(videoRef) {
    this.checkMicrophonePermissions()
    this.setCameraPreviewRef(videoRef)
    this.fetchAvailableDevices()
  }

  async saveChanges() {
    const selectedSettings: SelectedDeviceSettings = {}

    if (this.selectedCameraId !== this.storedCameraId) {
      localStorage.setItem('cameraDeviceId', this.selectedCameraId)
      selectedSettings.videoDeviceId = this.selectedCameraId
    }

    if (this.selectedMicrophoneId !== this.storedMicrophoneId) {
      localStorage.setItem('microphoneDeviceId', this.selectedMicrophoneId)
      selectedSettings.microphoneDeviceId = this.selectedMicrophoneId
    }

    if (this.selectedSpeakerId !== this.storedSpeakerId) {
      localStorage.setItem('speakerDeviceId', this.selectedSpeakerId)
      selectedSettings.speakerDeviceId = this.selectedSpeakerId
    }

    if (User.isVirtualBackgroundEnabled) {
      const isBackgroundChanged = !!this.storedVideoBackground !== !!this.hasSelectedVideoBackground
      const hasBackground = isBackgroundChanged ? !!this.hasSelectedVideoBackground : undefined

      if (isBackgroundChanged) {
        localStorage.setItem('videoBackground', String(hasBackground))
        selectedSettings.hasBackground = hasBackground
      }
    }

    await this.callInstance?.applyDeviceSettingsChanges?.(selectedSettings)
  }

  reset() {
    this.unsubscribeFromMicrophonePermissionsChange()
    this.stopCameraPreviewTracks()
    this.microphoneVisualizer.stop()
    this.stopMicrophonePreviewTrack()
    this.setSelectedCameraId('')
    this.setSelectedMicrophoneId('')
    this.setSelectedSpeakerId('')
    this.setSelectedVideoBackground(true)
  }

  get isMicrophonesReady() {
    return this.availableMicrophones?.some((i) => !!i.deviceId)
  }

  get isSpeakersReady() {
    return this.availableSpeakers?.some((i) => !!i.deviceId)
  }

  get isCamerasReady() {
    return this.availableCameras?.some((i) => !!i.deviceId)
  }
}

export default DeviceSettings
