import { makeObservable } from 'mobx'
import differenceInYears from 'date-fns/differenceInYears'
import MemberPrivateData from 'singletons/MemberPrivateData'
import MemberExtendedData from 'singletons/MemberExtendedData'
import GroupServices from 'singletons/GroupServices'
import ClientData from 'singletons/ClientData'
import Record from 'models/Record'
import { formatDate } from 'util/formatters'
import { statesByValue } from 'constants/states'
import { countriesByValue } from 'constants/countries'
import VisitTypes, { isSuccessVisit } from 'constants/visit-types'
import { memberGroupTypes } from 'constants/memberGroupTypes'

class VisitBase extends Record {
  static INITIAL_FIELDS = {
    clientId: '',
    groupId: '',
    createdAt: '',
    externalId: '',
    location: '',
    locationCountry: '',
    memberDob: '',
    memberId: '',
    modality: '',
    pendingVisitId: '',
    providerId: '',
    phone: '',
    reasonForVisit: '',
    scheduledAt: '',
    sentToReviewAt: '',
    scheduledType: '',
    status: '',
    timezone: '',
    updatedAt: '',
    visitType: '',
    member: {},
  }
  constructor(valuesObj) {
    super(valuesObj)
    makeObservable(this, {
      fullLocation: true,
      formattedDob: true,
    })
  }

  get claimed() {
    return this.scheduledType === 'now' && !!this.providerId
  }

  get canBeClaimed() {
    return this.scheduledType === 'now' && !this.providerId
  }

  get started() {
    switch (this.status) {
      case 'in-progress':
      case 'in-review':
      case 'completed':
        return true
      default:
        return false
    }
  }
  get canStartVideoCall() {
    switch (this.status) {
      case 'in-review':
      case 'completed':
        return false
      default:
        return true
    }
  }
  get external() {
    return !!this.externalId
  }
  get age() {
    return differenceInYears(Date.now(), new Date(this.memberDob))
  }
  get formattedDob() {
    return formatDate(this.memberDob)
  }
  get gender() {
    return this.memberExtendedData?.privateData?.member?.gender || ''
  }
  get genderIdentity() {
    return this.genderSelfSelect || this.memberExtendedData?.privateData?.member?.genderIdentity || ''
  }
  get genderSelfSelect() {
    return this.memberExtendedData?.privateData?.member?.genderSelfSelect || ''
  }
  get fullLocation() {
    return this.location ? statesByValue[this.location] : countriesByValue[this.locationCountry]
  }
  get privateData() {
    return MemberPrivateData.get(this.memberId)
  }
  get memberExtendedData() {
    return MemberExtendedData.get(this.memberId, isSuccessVisit(this.visitType) ? VisitTypes.success : '')
  }
  get memberServices() {
    return GroupServices.get(this.clientId, this.groupId)
  }
  async dirtyRefetchMemberExtendedData() {
    await MemberExtendedData.refetch(this.memberId)
  }
  get extDataVisits() {
    const ext = this.memberExtendedData
    return ext?.visits || null
  }
  get clientData() {
    const ext = this.memberExtendedData
    return ext ? ClientData.get(ext.privateData.member.clientId) : null
  }
  get memberFirstName() {
    return this.privateData?.preferredName ?? ''
  }
  get memberLastName() {
    return this.privateData?.lastName ?? ''
  }
  get memberFullName() {
    return this.privateData?.fullName ?? ''
  }
  get isMemberLocked() {
    return (
      !this.memberExtendedData?.privateData?.member?.status ||
      this.memberExtendedData?.privateData?.member?.status === 'locked'
    )
  }
  get memberGroupType() {
    return Object.keys(memberGroupTypes).includes(this.member?.groupType)
      ? memberGroupTypes[this.member?.groupType]
      : ''
  }

  get panelPrivateInfo() {
    const { formattedDob, age, fullLocation, gender } = this
    return `${formattedDob} • (${age}) (${gender}) ${fullLocation}`
  }
}
export default VisitBase
