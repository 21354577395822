import makeQuery from 'api/makeQuery'
import { API_INTAKE_SCORES_FETCH_LIMIT } from 'constants/apiFetchLimits'
import { action, makeAutoObservable, observable, onBecomeObserved, reaction } from 'mobx'
import Pagination from 'models/Pagination/Pagination'
import { AssessmentScore } from 'types/Visit/MeasurementBasedCare'
import mergeMobxListsBy from 'util/mergeMobxListsBy'
import Score, { MbcScore } from './Score'

export const DATE_FORMAT = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
} as const

export type IntakeType = 'gad7' | 'phq9'
// MBC = Measurement Based Care
class MbcAssessmentScores {
  // TODO: when PendingVisit is typed add type
  root: any
  intakeType: IntakeType
  list: Score[] = []
  fullList: Score[] = []
  currentPage: AssessmentScore[] = []
  map = new Map()
  loading: boolean
  viewAll = false
  pagination: Pagination
  disposeScoreFetcher: () => void

  constructor(root: any, intakeType: IntakeType) {
    this.root = root
    this.intakeType = intakeType
    this.loading = false
    this.pagination = new Pagination(API_INTAKE_SCORES_FETCH_LIMIT)

    makeAutoObservable(this, {
      map: false,
      list: observable.shallow,
      fullList: observable.shallow,
      pagination: observable.shallow,
      currentPage: observable.shallow,
      setCurrentPage: action.bound,
      toggleViewAll: action.bound,
    })

    this.disposeScoreFetcher = onBecomeObserved(this, 'list', () => {
      this.pagination.setPageSize(API_INTAKE_SCORES_FETCH_LIMIT)
      this.fetch()
    })

    reaction(
      () => this.pagination.page,
      (page) => {
        if (page >= 0) this.fetch()
      },
    )

    reaction(
      () => this.viewAll,
      (viewAll) => {
        if (viewAll) this.fetchAll()
        else this.fetch()
      },
    )
  }

  destroy() {
    this.disposeScoreFetcher()
  }

  setIsLoading(isLoading: boolean) {
    this.loading = isLoading
  }

  setCurrentPage() {
    if (this.viewAll) {
      this.currentPage = this.list.map((score) => score.displayValues)
    } else {
      this.currentPage = this.list
        .slice(this.pagination.startIndex, this.pagination.endIndex)
        .map((score) => score.displayValues)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.currentPage.sort((a: Score, b: Score) => new Date(a.date) - new Date(b.date))
  }

  mergeMbcAssessmentScores(mbcAssessmentScores: any, shouldDelete: boolean) {
    mergeMobxListsBy(
      this.map,
      this.list,
      mbcAssessmentScores,
      'pendingVisitId',
      (v: MbcScore) => {
        return new Score({
          score: v.score,
          date: new Date(v.scheduledAt).toLocaleString('en-US', DATE_FORMAT),
          visitType: v.visitType,
        })
      },
      shouldDelete,
    )
  }

  *fetch() {
    this.setIsLoading(true)
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = yield makeQuery('getMbcScores', {
        filter: {
          member_id: this.root.memberId,
          intake_type: this.intakeType,
        },
        page: {
          skip: this.pagination.page * API_INTAKE_SCORES_FETCH_LIMIT,
          limit: API_INTAKE_SCORES_FETCH_LIMIT,
        },
        sort: {
          scheduled_at: -1,
        },
      })
      this.mergeMbcAssessmentScores(result.paginated, false)
      this.pagination.total = result.meta.results
    } finally {
      this.setCurrentPage()
      this.setIsLoading(false)
    }
  }

  *fetchAll() {
    this.setIsLoading(true)
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result = yield makeQuery('getMbcScores', {
        filter: {
          member_id: this.root.memberId,
          intake_type: this.intakeType,
        },
        page: {
          skip: 0,
          limit: 500,
        },
        sort: {
          scheduled_at: -1,
        },
      })
      this.mergeMbcAssessmentScores(result.paginated, false)
      this.pagination.total = result.meta.results
    } finally {
      this.setCurrentPage()
      this.setIsLoading(false)
    }
  }

  get currentPageSize() {
    return this.list.length
  }

  toggleViewAll() {
    this.viewAll = !this.viewAll
  }

  get maxScore() {
    if (this.intakeType === 'gad7') return 21
    if (this.intakeType === 'phq9') return 27
  }

  get chartLabel() {
    if (this.intakeType === 'gad7') return 'GAD-7'
    if (this.intakeType === 'phq9') return 'PHQ-9'
  }
  get currentPageNumber() {
    return this.pagination.lastPageNumber - this.pagination.page
  }
}

export default MbcAssessmentScores
