/* SHOULD NOT NORMALLY BE USED!! */

import { object, array, boolean, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  memberId: string().required(),
  pendingVisitId: string().required(),
  dxs: array().required(),
  rxs: array().required(),
  notes: array().required(),
  followupInstructions: string().required(),
  followup: boolean().required(),
})

export const url = ({ visitId }) => `/v1/visit/${visitId}`

export const getBody = ({ visitId: _, ...rest }) => ({
  visit: rest,
})
