import { action, flow, makeObservable, observable, reaction } from 'mobx'
import { Device, Call } from '@twilio/voice-sdk'
import makeQuery from 'api/makeQuery'
import GlobalEvents from 'singletons/GlobalEvents'
import PendingQueries from 'singletons/PendingQueries'
import { PHONE_CALL_STATUS } from 'constants/phoneCallStatuses'
import PhoneCallBase from 'models/PhoneCallBase'
import User from 'singletons/User'
import { CallInstance, CallInstanceType } from 'types/CallInstance'
import DeviceSettings from 'models/DeviceSettings'
import { IS_SAFARI } from 'util/browsers'
import { captureException } from 'util/apm'

class PhoneCall extends PhoneCallBase implements CallInstance {
  type: CallInstanceType = 'phone'
  device: Device = null
  currentCall: Call = null
  deviceSettings = new DeviceSettings(this)
  startedAt: number | null = null
  isMuted = false

  constructor(visit) {
    super(visit)

    makeObservable(this, {
      device: observable.ref,
      currentCall: observable.ref,
      fetchToken: flow,
      initializeDevice: action,
      addDeviceListeners: action,
      makeOutgoingCall: flow,
      startedAt: true,
      isMuted: true,
      toggleIsMuted: action,
    })

    reaction(
      () => this.status,
      (newValue) => {
        if (newValue === PHONE_CALL_STATUS.CONNECTED) {
          this.startedAt = this.startedAt || new Date().valueOf()
        } else {
          this.startedAt = null
        }
      },
    )
  }

  *fetchToken({ visitId, providerId }: { visitId: string; providerId: string }) {
    const token = yield makeQuery('getPhoneCallToken', {
      visitId,
      providerId,
    })
    return token
  }

  async initializeDevice({ token }: { token: string }) {
    const device = new Device(token, {
      logLevel: 1,
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    })

    // Without this we run into a race condition with the Twilio Device class obtaining available devices and setting a device.
    await navigator.mediaDevices.getUserMedia({ audio: true })

    // Try/catch is necessary here because each time page is reloaded, Safari asks for Microphone permissions again
    // And after allowing microphone usage it throws error "Device not found" for stored microphone device
    // Without catching this error it fails starting phone call
    try {
      const microphoneDeviceId = localStorage.getItem('microphoneDeviceId') || 'default'
      await device.audio.setInputDevice(microphoneDeviceId)
    } catch (error) {
      console.log('Failed to set stored input device', error)
    }

    // Setting output devices is not supported on Safari
    if (!IS_SAFARI) {
      try {
        const speakerDeviceId = localStorage.getItem('speakerDeviceId') || 'default'
        await device.audio.speakerDevices.set(speakerDeviceId)
      } catch (error) {
        console.log('Failed to set stored output device', error)
      }
    }

    this.device = device
    this.addDeviceListeners()
  }

  addDeviceListeners() {
    this.device.on('registered', () => {
      this.status = PHONE_CALL_STATUS.READY
    })

    this.device.on('error', (error) => {
      GlobalEvents.emit('visitStartPhone', {
        status: 'error',
        description: 'Unable to start conference',
        visit: this.visit,
        error: `Device Error: ${error}`,
      })
      captureException(error)
    })
  }

  *makeOutgoingCall({ phone }: { phone: string }) {
    this.status = PHONE_CALL_STATUS.CONNECTING
    const params = {
      To: phone,
    }

    if (this.device) {
      const call: Call = yield this.device.connect({ params })
      this.currentCall = call

      this.toggleIsMuted(false)

      call.on('accept', (connection) => {
        this.status = PHONE_CALL_STATUS.CONNECTED
      })
      // Twilio Voice library does not have a connection type
      call.on('disconnect', async (connection: { parameters?: { CallSid?: string } }) => {
        await makeQuery('postConferencesCallback', {
          visitId: this.visit.visitId,
          // Defaulting to null instead of undefined
          externalId: connection.parameters?.CallSid ?? null,
          // Hardcoded status since connection object does not have a good status property
          status: 'completed',
        })
        this.end()
      })
      call.on('cancel', (connection) => {
        this.status = PHONE_CALL_STATUS.OFFLINE
        this.currentCall = null
      })
    } else {
      this.status = PHONE_CALL_STATUS.OFFLINE
      GlobalEvents.emit('visitStartPhone', {
        status: 'error',
        description: 'Unable to start conference',
        visit: this.visit,
        error: 'Devices have not been initialized',
      })
    }
  }

  *start() {
    this.status = PHONE_CALL_STATUS.INPROGRESS
    try {
      if (this.visit && !this.visit.started) {
        yield this?.visit.start()
      }
      const providerPhone = User.provider?.preferredPhone?.number
      if (!providerPhone) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(User.provider)
        }
        throw new Error('Provider phone is empty')
      }

      const memberPhone = this.visit.phone?.number

      if (!memberPhone) {
        throw new Error('Member phone is empty')
      }
      const token = yield this.fetchToken({
        visitId: this.visit.visitId,
        providerId: this.visit.providerId,
      })
      yield this.initializeDevice({ token })
      this.makeOutgoingCall({ phone: this.visit.phone.number })
      this.status = PHONE_CALL_STATUS.CONNECTED
    } catch (e) {
      GlobalEvents.emit('visitStartPhone', {
        status: 'error',
        description: 'Unable to start conference',
        visit: this.visit,
        error: e ? `Start phone call error: ${e?.toString()}` : 'Unknown Error',
      })
      console.log(e)
      captureException(e)
      this.status = PHONE_CALL_STATUS.OFFLINE
    }
  }

  end() {
    this.status = PHONE_CALL_STATUS.OFFLINE
    if (this.currentCall) {
      this.currentCall.removeAllListeners()
      this.currentCall.disconnect()
      this.currentCall = null
      this.isMuted = false
    }
    if (this.device) {
      this.device.destroy()
      this.device = null
    }
  }

  get isStartPhoneCallDisabled() {
    return (
      PendingQueries.some('getPhoneCallToken') ||
      !this.status ||
      this.status === PHONE_CALL_STATUS.CONNECTING ||
      this.status === PHONE_CALL_STATUS.CONNECTED
    )
  }

  publishNewMicrophone(microphoneDeviceId) {
    if (microphoneDeviceId) {
      this.device?.audio?.setInputDevice(microphoneDeviceId)
    }
  }

  publishNewSpeaker(speakerDeviceId) {
    if (speakerDeviceId && !IS_SAFARI) {
      this.device?.audio?.speakerDevices?.set(speakerDeviceId)
    }
  }

  async applyDeviceSettingsChanges({
    microphoneDeviceId,
    speakerDeviceId,
  }: {
    microphoneDeviceId?: string
    speakerDeviceId?: string
  }) {
    this.publishNewMicrophone(microphoneDeviceId)
    this.publishNewSpeaker(speakerDeviceId)
  }

  toggleIsMuted = (_isMuted: boolean) => {
    if (!this.currentCall) {
      return
    }

    if (this.currentCall.isMuted() !== _isMuted) {
      this.currentCall.mute(_isMuted)
    }

    this.isMuted = _isMuted
  }
}

export default PhoneCall
