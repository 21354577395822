import Record from 'models/Record'
import makeQuery from 'api/makeQuery'
import mapValues from 'lodash/mapValues'
import { makeObservable, when, onBecomeObserved, computed } from 'mobx'
import User from 'singletons/User'

const STATUSES = {
  Active: 'Created',
  CancelRequested: 'Cancel Requested',
  Unknown: 'Unknown',
  CancelPending: 'Cancel Pending',
  Cancelled: 'Cancelled',
  CancelDenied: 'Cancel Denied',
  Entered: 'Submitted',
  Sending: 'Sending to Pharmacy',
  Requested: 'Change Requested',
  Error: 'Error',
  eRxSent: 'RX Sent to Pharmacy',
  PharmacyVerified: 'Pharmacy Verified',
}

class Rx extends Record {
  static INITIAL_FIELDS = {
    id: 0,
    prescriptionId: '',
    memberId: '',
    memberExternalId: '',
    prescriptionChangeId: null,
    providerId: '',
    providerExternalId: '',
    pharmacyId: 0,
    visitId: '',
    externalId: '',
    medicationId: null,
    dispensableDrugId: '',
    ndc: '',
    name: '',
    unit: '',
    log: [],
    quantity: 0,
    directions: '',
    status: '',
    refills: 0,
    archived: false,
    substitutions: true,
    pharmacyNotes: null,
    daysSupply: null,
    archivedAt: null,
    cancelledAt: null,
    cancellationReason: null,
    filledAt: '',
    effectiveAt: '',
    dispenseUnit: null,
    pharmacy: null,
  }

  get storedOnDoseSpot() {
    return !!this.externalId
  }

  get logStatus() {
    if (this.log?.length) {
      const { medicationStatus, prescriptionStatus } = this.log[0]

      switch (medicationStatus) {
        case 'Active':
        case 'CancelRequested':
        case 'CancelPending':
        case 'Cancelled':
        case 'CancelDenied':
          return STATUSES[medicationStatus]
        case 'Unknown':
          return prescriptionStatus ? STATUSES[prescriptionStatus] : ''
        default:
          return ''
      }
    }
    return ''
  }

  get removalForbidden() {
    return this.status === 'cancelled'
  }

  get logAdditionalInfo() {
    return (this.log?.length && this.log[0].additionalInfo) || ''
  }

  get logName() {
    const { name, logStatus, logAdditionalInfo } = this

    let result = name

    if (logStatus) {
      result += ` [${logStatus}]`
    }

    if (logAdditionalInfo) {
      result += ` (${logAdditionalInfo})`
    }

    return result
  }

  get totalUnit() {
    const baseUnit = this.unit || ''
    const singularOrPlural = this.dispenseUnit?.singularOrPlural || ''
    return `${baseUnit} ${singularOrPlural}`.trim()
  }

  constructor(rxs, initialValues) {
    super(initialValues)

    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      destroy: false,
      map: false,
      remove: false,
      logStatus: true,
      logName: true,
      totalUnit: true,
    })

    this.rxs = rxs

    this.disposeWhen = null
    this.disposeLogFetcher = onBecomeObserved(this, 'log', () => {
      this.disposeWhen && this.disposeWhen()
      this.disposeWhen = when(
        () => this.prescriptionId,
        () => this.refetch(),
      )
    })
  }

  async refetch() {
    if (!User.isHidePrescriptionsFeatureFlagEnabled) {
      const prescription = await makeQuery('getPrescription', {
        prescriptionId: this.prescriptionId,
      })
      this.merge(prescription)
    }
  }

  async remove(cancellationReason) {
    if (this.removalForbidden) {
      throw new Error('It is forbidden to remove this rx')
    } else {
      await makeQuery('putRxCancel', {
        visitId: this.rxs.visit.visitId,
        rxId: this.prescriptionId,
        cancellationReason,
      })
      /* returns null, so unsafe to merge here */
    }
  }

  destroy() {
    this.disposeLogFetcher()
    this.disposeWhen && this.disposeWhen()
  }
}

export default Rx
