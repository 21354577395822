import { action, makeAutoObservable, observable, runInAction } from 'mobx'

import makeQuery from 'api/makeQuery'
import User from 'singletons/User'
import { getBase64 } from 'util/images'
export { provider as baseURL } from 'constants/apiBaseUrls'

class Images {
  constructor(visit) {
    this.list = []
    this.thumbs = {}
    this.isFetched = false
    this.imageSrc = ''
    this.isImageLoading = false

    makeAutoObservable(this, {
      list: observable.shallow,
      map: false,
      fetchImage: action.bound,
    })

    this.visit = visit
  }

  get length() {
    return this.list.length
  }

  map(cb) {
    return this.list.map(cb)
  }

  async refetch() {
    const imagesRequest = User.isV2ImageEndpointsEnabled ? 'getMemberVisitImagesV2' : 'getMemberVisitImages'

    const imagesList = await makeQuery(imagesRequest, {
      memberId: this.visit.memberId,
      pendingVisitId: this.visit.pendingVisitId,
    })
    runInAction(() => {
      this.list.replace(imagesList)
      this.isFetched = true
    })
  }

  updateImages(images) {
    this.list.replace(images || [])
  }

  async fetchImage(imageId) {
    const imageRequest = User.isV2ImageEndpointsEnabled ? 'getMemberVisitImageV2' : 'getMemberVisitImage'

    try {
      runInAction(() => {
        this.isImageLoading = true
      })

      const blob = await makeQuery(imageRequest, {
        memberId: this.visit.memberId,
        imageId,
      })

      const base64 = await getBase64(blob)
      const imageSrc = `data:image/png;base64,${base64}`

      runInAction(() => {
        this.imageSrc = imageSrc
        this.isImageLoading = false
      })
    } catch (e) {
      console.error('Error retrieving image:', e)
    }
  }

  async fetchThumbImages() {
    try {
      await Promise.all(this.list.map((image) => this.fetchThumbImage(image.imageId)))
    } catch (error) {
      console.error('Error retrieving thumbnail images:', error)
    }
  }

  async fetchThumbImage(imageId) {
    const thumbnailRequest = User.isV2ImageEndpointsEnabled ? 'getMemberVisitThumbImageV2' : 'getMemberVisitThumbImage'

    try {
      const blob = await makeQuery(thumbnailRequest, {
        memberId: this.visit.memberId,
        imageId,
      })

      const base64 = await getBase64(blob)

      runInAction(() => {
        this.thumbs[imageId] = `data:image/png;base64,${base64}`
      })
    } catch (e) {
      console.error('Error retrieving thumbnail image:', e)
    }
  }

  getThumbSrc(imageId) {
    return this.thumbs[imageId]
  }
}

export default Images
