import * as Yup from 'yup'

const PhoneSchema = Yup.object().shape({
  type: Yup.string()
    .label('Phone Type')
    .oneOf(['mobile', 'home', 'office'], 'Must be one of Mobile, Home, or Office')
    .required(),
  number: Yup.string()
    .label('Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must be a 10 digit number')
    .required(),
  acceptSms: Yup.boolean().nullable().notRequired(),
  preferred: Yup.boolean().nullable().notRequired(),
})

export default PhoneSchema
