import { object, string } from 'yup'

const PasswordSchema = object().shape({
  password: string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required('Password required'),
})

export default PasswordSchema
