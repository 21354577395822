import { number, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  status: string().required(),
  visitId: string().required(),
  statusReason: string(),
  statusReasonCode: number().notRequired().nullable(),
  cancelledBy: string().notRequired().nullable(),
  cancellationNotes: string().notRequired().nullable(),
})

export const url = ({ visitId }) => `/v1/visits/pending/${visitId}/status`

export const getBody = ({ cancelledBy, status, statusReason, statusReasonCode, cancellationNotes }) => ({
  cancelledBy,
  status,
  statusReason,
  statusReasonCode,
  cancellationNotes,
})
