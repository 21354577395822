import qs from 'qs'
import { Location } from 'react-router'
import { DosespotNotConfirmedResponse } from 'types/Login/DosespotNotConfirmedResponse'

export const detectBrowser = () => {
  const userAgent = window.navigator.userAgent
  if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
    return 'Opera'
  } else if (userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  } else if (userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  } else if (userAgent.indexOf('MSIE') !== -1) {
    return 'IE'
  } else {
    return 'Unknown'
  }
}

export const buildQueryParams = (location: Location) => {
  return qs.stringify({
    origin: 'provider',
    origin_meta: {
      browser: detectBrowser(),
      referrer_path: location?.state?.referrer || '/',
      user_agent: window.navigator.userAgent,
      language: window.navigator.language,
      platform: window.navigator.platform,
      vendor: window.navigator.vendor,
      window_height: window.screen.height,
      window_width: window.screen.width,
    },
  })
}

export const isDosespotNotConfirmed = (data: any): data is DosespotNotConfirmedResponse => 'status' in data
