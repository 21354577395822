import { InferType, number, object, string } from 'yup'
import queryString from 'qs'
import { MedicationsListResponse } from 'types/Medications'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  filter: object({
    name: string().required(),
  }),
  current_page: number().required(),
})

export type Request = InferType<typeof Schema>

export const url = ({ filter, current_page }) => `/v2/medications?${queryString.stringify({ filter, current_page })}`

export type SuccessfulResponse = MedicationsListResponse
