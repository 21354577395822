import makeQuery from 'api/makeQuery'
import { startOfMonth } from 'date-fns'
import { action, comparer, computed, makeObservable, observable, onBecomeObserved, reaction } from 'mobx'

const PAGE_SIZE = 10

class Payments {
  constructor(provider) {
    this.list = []
    this.filter = {
      startDate: '',
      endDate: '',
    }
    this.page = 0
    this.provider = provider
    this.isDownloading = false
    makeObservable(this, {
      list: observable.ref,
      filter: true,
      paginated: true,
      filteredList: computed({ equals: comparer.shallow }),
      pageCount: true,
      page: true,
      resetPage: action,
      setStartDate: action.bound,
      setEndDate: action.bound,
      setPage: action,
      fetchPayments: true,
      downloadPayment: true,
    })
    this.disposePaymentsFetcher = onBecomeObserved(this, 'list', () => {
      if (this.list.length === 0) {
        this.fetchPayments()
      }
    })
    this.disposeResetPageReaction = reaction(
      () => [this.filter.startDate, this.filter.endDate],
      () => this.resetPage(),
    )
  }

  destroy() {
    this.disposePaymentsFetcher()
    this.disposeResetPageReaction()
  }

  get filteredList() {
    return this.filter.startDate || this.filter.endDate
      ? this.list.filter((payment) => {
          const paymentDate = new Date(payment.year, payment.month - 1)
          const isDateAfter = !this.filter.startDate || paymentDate >= this.filter.startDate
          const isDateBefore = !this.filter.endDate || paymentDate <= this.filter.endDate
          return isDateBefore && isDateAfter
        })
      : this.list
  }

  get paginated() {
    return this.filteredList.slice(this.page * PAGE_SIZE, (this.page + 1) * PAGE_SIZE)
  }

  get pageCount() {
    return Math.ceil(this.list.length / PAGE_SIZE)
  }

  setIsDownloading(isDownloading) {
    this.isDownloading = isDownloading
  }

  resetPage() {
    this.page = 0
  }

  setStartDate(date) {
    if (date && date !== 'Invalid Date') {
      this.filter.startDate = startOfMonth(date)
    } else {
      this.filter.startDate = null
    }
  }

  setEndDate(date) {
    if (date && date !== 'Invalid Date') {
      this.filter.endDate = startOfMonth(date)
    } else {
      this.filter.endDate = null
    }
  }

  setPage(page) {
    if (page != this.page && page <= this.pageCount) {
      this.page = page
    }
  }

  *fetchPayments() {
    const payments = yield makeQuery('getProviderPayments', {
      providerId: this.provider.providerId,
    })
    this.list = payments ? payments.sort((a, b) => new Date(b.year, b.month) - new Date(a.year, a.month)) : []
  }

  *downloadPayment(paymentId, year, month, downloadPaymentRef) {
    this.setIsDownloading(true)
    const paymentPdf = yield makeQuery('getProviderPayment', {
      providerId: this.provider.providerId,
      paymentId: paymentId,
    })
    const blob = new Blob([paymentPdf], {
      type: 'application/pdf',
    })
    const href = window.URL.createObjectURL(blob)
    const a = downloadPaymentRef.current
    a.download = `${year}-${month}-TimelyProvider-Payments.pdf`
    a.href = href
    a.click()
    a.href = ''
    this.setIsDownloading(false)
  }
}

export default Payments
