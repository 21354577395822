import { PHONE_CALL_STATUS, PhoneCallStatus } from 'constants/phoneCallStatuses'
import { action, flow, makeObservable } from 'mobx'

abstract class PhoneCallBase {
  status: PhoneCallStatus = PHONE_CALL_STATUS.OFFLINE
  // TODO: Type with Visit Model
  visit: any = null

  constructor(visit) {
    this.visit = visit
    makeObservable(this, {
      status: true,
      visit: true,
      setStatus: action,
      start: flow,
      end: action,
    })
  }

  setStatus(status: PhoneCallStatus) {
    this.status = status
  }

  abstract start(): void

  abstract end(): void
}

export default PhoneCallBase
