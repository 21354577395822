import { InferType, mixed, object, string } from 'yup'
import queryString from 'qs'
import { Schedule } from 'types/Provider/Schedule'
import { PaginatedResponse } from 'types/api'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  filter: mixed().notRequired(),
  gte: object().notRequired(),
  lte: object().notRequired(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = PaginatedResponse<Schedule>

export const url = ({ filter, providerId, gte, lte }: Request) =>
  `/v2/providers/${providerId}/schedules?${queryString.stringify(
    { filter, gte, lte, page: { limit: 100, skip: 0 } },
    { indices: false },
  )}`
