import { object, array } from 'yup'

export { member as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  'filter[_id]': array(),
})

export const url = () => `/v1/members/list`

export const getBody = (params) => ({ id: params['filter[_id]'] })
