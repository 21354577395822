import { InferType, number, object, string } from 'yup'
import qs from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  memberId: string().required(),
  visitType: string(),
  endWeeks: number(),
})

export type Request = InferType<typeof Schema>

// TODO: Create ISO date string type
export type SuccessfulResponse = string[]

export const url = ({ providerId, memberId, visitType, endWeeks }: Request) =>
  `/v1/providers/${providerId}/availability?${qs.stringify({
    filter: {
      member_id: memberId,
      visit_type: visitType,
    },
    end_weeks: endWeeks,
  })}`
