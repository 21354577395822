import { makeAutoObservable, reaction, when, computed, comparer } from 'mobx'
import keyBy from 'lodash/keyBy'
import makeQuery from 'api/makeQuery'
import Question from './Question'
import User from 'singletons/User'

class IntakeQuestionnaire {
  createdAt = ''
  deletedAt = ''
  questionnaireId = ''
  questions = []

  constructor(visit) {
    makeAutoObservable(this, {
      refetch: false,
      save: false,
      syncedQuestionsById: computed({ equals: comparer.structural }),
    })

    this.visit = visit

    this.disposeAutosave = reaction(
      () =>
        this.questionnaireId && this.fetched
          ? this.questions.map(({ id, answers }) => ({
              questionId: id,
              answers: answers.flatMap((a) => (a === '' ? [] : ['' + a])),
            }))
          : null,
      // TODO: check for better solution
      (v, prevV) => v && prevV && this.save(v),
      { delay: 1700, equals: comparer.structural },
    )
  }

  destroy() {
    this.disposeAutosave()
    this.questions.forEach((question) => {
      question.destroy()
    })
  }

  get fetched() {
    return !!this.questions.length
  }

  get syncedQuestionsById() {
    return this.visit.assessment?.questions?.reduce((acc, { questionId, answers }) => {
      acc[questionId] = answers.map((a) => (isNaN(a) ? a : parseInt(a, 10)))
      return acc
    }, {})
  }

  /* for prereq */
  get byUniqueId() {
    return keyBy(this.questions, 'uniqueId')
  }

  get firstMissingQuestion() {
    return this.questions.find((q) => q.missingAnswer)
  }

  get missingQuestionsLength() {
    return this.questions.reduce((acc, q) => acc + (q.missingAnswer ? 1 : 0), 0)
  }

  get filled() {
    return !this.firstMissingQuestion
  }

  replace({ createdAt, deletedAt, questionnaireId, questions }) {
    this.createdAt = createdAt
    this.deletedAt = deletedAt
    this.questionnaireId = questionnaireId

    const { syncedQuestionsById } = this

    if (process.env.NODE_ENV !== 'production') {
      if (!this.visit.assessment) {
        console.warn('Assessment should not be empty here')
      }
    }

    this.questions.replace(questions.map((q) => new Question(this, q, syncedQuestionsById?.[q.id])))
  }

  async save(questions) {
    const { questionnaireId } = this

    if (!questionnaireId) {
      throw new Error('No questionnaireId')
    }

    const resp = await makeQuery('putVisitAssessment', {
      visitId: this.visit.visitId,
      questionnaireId,
      questions,
    })

    this.visit.merge(resp)
  }

  async refetch() {
    await when(() => this.visit.assessmentCompleted === false || !!this.visit.assessment)

    const { assessment } = this.visit

    const questionnaireId = assessment?.questionnaireId

    let result
    if (questionnaireId) {
      result = await makeQuery('getProviderQuestionnaire', {
        providerId: User.providerId,
        questionnaireId,
      })
    } else {
      const { questionnaireVisitType } = this.visit

      if (questionnaireVisitType) {
        result = await makeQuery('getProviderQuestionnaireLatest', {
          providerId: User.providerId,
          visitType: questionnaireVisitType,
        })
      }
    }

    if (result) {
      this.replace(result)
    }
  }
}

export default IntakeQuestionnaire
