import { makeAutoObservable, reaction } from 'mobx'
import {
  addDays,
  subDays,
  getMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  endOfMonth,
  startOfWeek,
  isSameMonth,
  startOfMonth,
  isWithinInterval,
  subMinutes,
  isAfter,
  isEqual,
} from 'date-fns'
import makeQuery from 'api/makeQuery'
import mergeMobxListsBy from 'util/mergeMobxListsBy'

import Schedule from './Schedule'
import TimeSlot from './Schedule/TimeSlot'

import { SlotType, type PendingSlot, type Schedule as ScheduleType } from 'types/Provider/Schedule'
import { ScheduleItemType, SlotFilter } from 'constants/schedule'

/** tz offset (in minutes ex: CST = 300) * 1 hour (in milliseconds) */
const getTimezoneOffset = (d: Date) => d.getTimezoneOffset() * 60000

export const offsetTimeToUTC = (date: Date) => {
  const offset = getTimezoneOffset(date)
  return new Date(offset > 0 ? date.getTime() - offset : date.getTime() + offset)
}

const flattenSlotsWithinInterval = (source: any[], start: Date, end: Date) =>
  source
    .filter((sched) => isWithinInterval(new Date(sched.date), { start, end }))
    .flatMap((s) => s.slots)
    .sort((a, b) => (new Date(a.startTime) > new Date(b.startTime) ? 1 : -1))

export type SectionedList = { slots: TimeSlot[]; filledSlots: TimeSlot[] }

class SchedulingsV2Collection {
  // TODO: type provider model
  provider: any
  /** selected date for Calendar */
  calendarDate = new Date()
  calendarFilter?: ScheduleItemType
  /** selected Date for AvailablilityDrawer */
  scheduleDate?: Date
  scheduleSlotsFilter: SlotFilter = 'all'
  list: Schedule[] = []
  /** [UTC dateString, Schedule] */
  fullMap: Map<string, Schedule> = new Map()
  scheduleChangesMade = false
  unsavedScheduleChanges = false
  unsavedChangesCallback?: () => void

  constructor(provider: any) {
    this.provider = provider
    makeAutoObservable(this)

    reaction(
      () => this.calendarDate,
      (current, prev) => {
        if (!isSameMonth(current, prev)) {
          this.refetch()
        }
      },
    )

    reaction(
      () => this.scheduleDate,
      (scheduleDate, prev) => {
        if (!isSameDay(scheduleDate, prev) && scheduleDate) {
          const foundSchedule = this.fullMap.get(offsetTimeToUTC(scheduleDate).toISOString())
          if (!foundSchedule) {
            this.refetch(this.scheduleDateUTCRange)
          }
        }
      },
    )
  }

  get currentCalendarMonth() {
    return getMonth(this.calendarDate)
  }

  get scheduleDatePastError() {
    return this.scheduleDate && this.scheduleDate < startOfDay(new Date())
      ? 'Cannot manage availability on a past date.'
      : undefined
  }

  get calendarMonthSlotCount() {
    const start = startOfMonth(this.calendarDate)
    const end = endOfMonth(this.calendarDate)
    const schedStart = offsetTimeToUTC(start)
    return flattenSlotsWithinInterval(this.list, schedStart, end).reduce<Record<SlotType | 'visits', number>>(
      (acc, slot) => {
        if (
          isWithinInterval(new Date(slot.startTime), { start, end: subMinutes(end, 1) }) &&
          (isAfter(new Date(slot.startTime), startOfDay(new Date())) ||
            isEqual(new Date(slot.startTime), startOfDay(new Date())))
        ) {
          if (slot.visit) acc.visits++
          else acc[slot.type]++
        }
        return acc
      },
      { initial: 0, followup: 0, visits: 0 },
    )
  }

  get localizedSlotsforSelectedDay(): TimeSlot[] {
    if (this.scheduleDate) {
      const start = this.scheduleDate
      const end = addDays(this.scheduleDate, 1)
      const schedStart = startOfDay(offsetTimeToUTC(start))
      const schedEnd = offsetTimeToUTC(end)
      return flattenSlotsWithinInterval(this.list, schedStart, schedEnd).filter((slot) => {
        if (!slot.visit) {
          if (isWithinInterval(new Date(slot.startTime), { start, end: subMinutes(end, 1) })) {
            if (this.scheduleSlotsFilter === 'all') return slot
            if (
              (slot.type === 'followup' && this.scheduleSlotsFilter !== 'initial') ||
              (slot.type === 'initial' && this.scheduleSlotsFilter !== 'followup')
            ) {
              return slot
            }
          }
        }
      })
    }
  }

  get localizedFilledSlotsforSelectedDay(): TimeSlot[] {
    if (this.scheduleDate) {
      const start = this.scheduleDate
      const end = addDays(this.scheduleDate, 1)
      const schedStart = startOfDay(offsetTimeToUTC(start))
      const schedEnd = offsetTimeToUTC(end)
      return flattenSlotsWithinInterval(this.list, schedStart, schedEnd).filter((slot) => {
        if (!!slot.visit && isWithinInterval(new Date(slot.startTime), { start, end: subMinutes(end, 1) })) {
          if (this.scheduleSlotsFilter === 'all') return slot
          if (
            (slot.type === 'followup' && this.scheduleSlotsFilter !== 'initial') ||
            (slot.type === 'initial' && this.scheduleSlotsFilter !== 'followup')
          ) {
            return slot
          }
        }
      })
    }
  }

  /** bc BE stores in UTC and FE is based on device TZ, we need to componsate for any overlapping slots that may exist on this day in this TZ */
  get scheduleDateUTCRange() {
    const offset = getTimezoneOffset(this.scheduleDate)
    return {
      gte: {
        date: (offset < 0 ? this.scheduleDate : subDays(startOfDay(this.scheduleDate), 1)).toISOString(),
      },
      lte: {
        date: (offset > 0 ? addDays(startOfDay(this.scheduleDate), 1) : this.scheduleDate).toISOString(),
      },
    }
  }

  /** this will be the entire range shown on the calendar view, including overlapping days in past/future months */
  get calendarRangeRefetchParams() {
    return {
      gte: this.calendarDate
        ? { date: offsetTimeToUTC(startOfWeek(startOfMonth(this.calendarDate))).toISOString() }
        : {},
      lte: this.calendarDate ? { date: offsetTimeToUTC(endOfWeek(endOfMonth(this.calendarDate))).toISOString() } : {},
    }
  }

  getDaySlotCountByType(type: SlotType | 'all', countVisits: boolean) {
    if (this.scheduleDate) {
      const start = this.scheduleDate
      const end = addDays(this.scheduleDate, 1)
      const schedStart = startOfDay(offsetTimeToUTC(start))
      const schedEnd = offsetTimeToUTC(end)
      return flattenSlotsWithinInterval(this.list, schedStart, schedEnd)?.reduce((acc, slot) => {
        if (isWithinInterval(new Date(slot.startTime), { start, end: subMinutes(end, 1) })) {
          if ((!!slot.visit && countVisits) || (!slot.visit && !countVisits)) {
            if (type === 'all') acc++
            else if (slot.type === type) acc++
          }
        }
        return acc
      }, 0)
    }
    return 0
  }

  getLocalizedSlotsForCalendarDay(date: Date): SectionedList {
    const start = date
    const end = addDays(date, 1)
    const schedStart = startOfDay(offsetTimeToUTC(start))
    const schedEnd = offsetTimeToUTC(end)
    return flattenSlotsWithinInterval(this.list, schedStart, schedEnd).reduce(
      (acc, slot) => {
        if (isWithinInterval(new Date(slot.startTime), { start, end: subMinutes(end, 1) })) {
          if (slot.visit) {
            if (this.calendarFilter == 'visits' || !this.calendarFilter) {
              acc.filledSlots.push(slot)
            }
          } else if (this.calendarFilter !== 'visits') {
            if (
              (slot.type === 'followup' && this.calendarFilter !== 'initial') ||
              (slot.type === 'initial' && this.calendarFilter !== 'followup')
            ) {
              acc.slots.push(slot)
            }
          }
        }
        return acc
      },
      { slots: [], filledSlots: [] },
    )
  }

  destroy() {
    this.fullMap.forEach((schedule) => schedule.destroy())
  }

  setCalendarFilter(filterValue: ScheduleItemType | undefined) {
    this.calendarFilter = filterValue
  }

  setScheduleSlotsFilter(filterValue: SlotFilter) {
    this.scheduleSlotsFilter = filterValue
  }

  setCalendarDate(date: Date) {
    this.calendarDate = date
  }

  setScheduleDate(timeStamp: number) {
    if (timeStamp) {
      this.scheduleDate = new Date(timeStamp)
    }
  }

  setScheduleChangesMade(val: boolean) {
    this.scheduleChangesMade = val
  }

  setUnsavedScheduleChanges(val: boolean) {
    this.unsavedScheduleChanges = val
  }

  setUnsavedChangesCallback(cb: () => void) {
    this.unsavedChangesCallback = cb
  }

  clearScheduleFields() {
    this.scheduleDate = undefined
    this.scheduleSlotsFilter = 'all'
    this.unsavedChangesCallback && this.unsavedChangesCallback()
  }

  getSlotById(slotId: string) {
    const allSlots = this.list.flatMap((schedule) => schedule.slots)
    return allSlots.find((s) => s.slotId === slotId)
  }

  getScheduleBySlotStartTime(startTime: string) {
    for (const [date, sched] of this.fullMap.entries()) {
      if (isWithinInterval(new Date(startTime), { start: new Date(date), end: addDays(new Date(date), 1) })) {
        return sched
      }
    }
  }

  mergeSchedules(schedules: ScheduleType[], shouldDelete: boolean = false) {
    mergeMobxListsBy(
      this.fullMap,
      this.list,
      schedules,
      'date',
      (s: ScheduleType) => new Schedule(s, this),
      shouldDelete,
    )
  }

  async refetch(range?: Record<string, Record<string, string>>) {
    // TODO: any for now bc ts is doing a lot of complaining about yup schema type
    let params: any = {
      providerId: this.provider.providerId,
    }
    if (range) {
      params = {
        ...params,
        ...range,
      }
    } else {
      params = {
        ...params,
        ...this.calendarRangeRefetchParams,
      }
    }
    const { paginated } = await makeQuery('getProviderSchedules', params)
    this.mergeSchedules(paginated)
  }

  async fetchOne(scheduleId: string) {
    const schedule = await makeQuery('getProviderSchedule', { providerId: this.provider.providerId, scheduleId })
    this.mergeSchedules([schedule])
  }

  async createSchedule(slot: PendingSlot) {
    const scheduleDate = new Date(slot.startTime).toISOString()
    const schedule = await makeQuery('postProviderSchedule', {
      providerId: this.provider.providerId,
      schedule: {
        date: scheduleDate,
        slot,
      },
    })
    this.mergeSchedules([schedule])
  }
}

export default SchedulingsV2Collection
