import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.dashboard.card2}`,

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

export default useStyles
