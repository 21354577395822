import { makeAutoObservable } from 'mobx'

export type Filter = {
  [key: string]: string | string[] | number | boolean | { [key: string]: string }
}

export type Sort = {
  [key: string]: 1 | -1
}

class Pagination {
  pageSize = 0
  filter?: Filter
  sort?: Sort
  total = 0
  page = 0
  limit = 0

  constructor(limit: number) {
    this.limit = limit
    makeAutoObservable(this)
  }

  setPageSize(pageSize: typeof this.pageSize) {
    this.pageSize = pageSize
  }

  setSort(sort: typeof this.sort) {
    this.sort = sort
  }

  setFilter(filter: typeof this.filter) {
    this.filter = filter
  }

  setPage(page: typeof this.page) {
    this.page = page
  }

  get startIndex() {
    return this.page * this.pageSize
  }

  get endIndex() {
    return Math.min(this.total, this.startIndex + this.pageSize)
  }

  get isSinglePage() {
    return this.total <= this.pageSize
  }

  get incremnetDisabled() {
    return this.page + 1 === this.lastPageNumber
  }

  get decrementDisabled() {
    return this.page === 0
  }

  get lastPageNumber() {
    return Math.ceil(this.total / this.limit)
  }
}

export default Pagination
