import { runInAction, observable } from 'mobx'
import throttle from 'lodash/throttle'
import makeQuery from 'api/makeQuery'

/*
    Some USA laws do not allow to store patient name, surname and diagnose in one db record,
    so they are stored separately.
*/

class MemberPrivateData {
  byMemberId = new Map()
  fetchQueue = new Set()

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const memberIds = Array.from(this.fetchQueue)

    const privateData = await makeQuery('postMemberPrivateData', {
      'filter[_id]': memberIds,
    })

    for (const k of memberIds) {
      this.fetchQueue.delete(k)
    }

    runInAction(() => {
      for (const { id, firstName, lastName, firstNamePreferred } of privateData) {
        const preferredName = firstNamePreferred ?? firstName
        const fullName = `${preferredName} ${lastName}`
        this.byMemberId.get(id)?.set({
          firstName,
          lastName,
          firstNamePreferred,
          fullName,
          preferredName,
        })
      }
    })

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 500)

  get(memberId) {
    let res = this.byMemberId.get(memberId)

    if (!res) {
      res = observable.box()
      this.byMemberId.set(memberId, res)
      this.fetchQueue.add(memberId)
      this.processQueue()
    }

    return res.get()
  }

  clear() {
    this.byMemberId.clear()
    this.fetchQueue.clear()
    this.processQueue.cancel()
  }
}

export default new MemberPrivateData()
