/* POLYFILLS START */
import 'core-js/modules/es.object.from-entries'
import 'core-js/modules/es.array.flat-map'
import 'core-js/modules/es.array.at'

import { createRoot } from 'react-dom/client'
import App from 'components/App'
import * as APM from 'util/apm'
import 'css/tailwind.css'
import 'react-pdf/dist/Page/TextLayer.css'

APM.initialize()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
