import { detectBrowser } from 'util/login'
import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  email: string().email().required(),
  password: string()
    .label('Password')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g,
      'Password must have 10 characters, and must contain 1 capital letter,' +
        ' 1 lowercase letter, 1 special character, and 1 number',
    )
    .required(),
})

export const url = () => '/v2/me/login'

export const getBody = ({ email, password, refreshMfaToken }) => ({
  email,
  password,
  refreshMfaToken,
  origin: 'web',
  originMeta: {
    browser: detectBrowser(),
    userAgent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.userAgentData?.platform || navigator.platform,
    vendor: navigator.vendor,
    windowHeight: screen.height,
    windowWidth: screen.width,
  },
})
