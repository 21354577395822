import { InferType, object, string } from 'yup'
import CrisisEscalation from 'types/Visit/PendingVisit/CrisisEscalation'
import { CrisisEscalationSchema } from 'schemas/CrisisEscalation'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  visitId: string().required(),
  escalation: object().concat(CrisisEscalationSchema),
})

export type SuccessfulResponse = CrisisEscalation

export type Request = InferType<typeof Schema>

export const url = ({ visitId }) => `/v1/visits/pending/${visitId}/escalations`

export const getBody = ({ escalation }) => escalation
