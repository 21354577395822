import CancelMedicationSchema from 'schemas/CancelMedication'
import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object()
  .shape({
    visitId: string().required(),
    rxId: string().required(),
  })
  .concat(CancelMedicationSchema)

export const url = ({ visitId, rxId }) => `/v1/visits/${visitId}/rx/${rxId}/cancel`

export const getBody = ({ cancellationReason }) => ({ cancellationReason })
