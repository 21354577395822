type getMaxWeeksForFollowUpParams = {
  scheduledType: string
  visitType: string
}

type MaxWeeksMap = {
  [scheduleType: string]: {
    [visitType: string]: number
  }
}

export const getMaxWeeksForFollowUp = ({
  scheduledType,
  visitType,
}: getMaxWeeksForFollowUpParams): number | undefined => {
  const maxWeeksMap: MaxWeeksMap = {
    scheduled: {
      therapy: 6,
      psychiatry: 12,
    },
  }
  return maxWeeksMap[scheduledType]?.[visitType]
}
