import { Component } from 'react'
import PropTypes from 'prop-types'
import reportError from 'util/reportError'

const promiseErrorEventHandler = ({ reason }) => reportError('PROMISE', reason)

const globalErrorEventHandler = ({ message, filename, lineno, colno, error }) =>
  reportError('ERROR', error, message, filename, lineno, colno)

class ErrorCatcher extends Component {
  componentDidCatch(err) {
    reportError('COMPONENT', err)
  }

  componentDidMount() {
    window.addEventListener('error', globalErrorEventHandler)
    window.addEventListener('unhandledrejection', promiseErrorEventHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('error', globalErrorEventHandler)
    window.removeEventListener('unhandledrejection', promiseErrorEventHandler)
  }

  render() {
    return this.props.children
  }
}

ErrorCatcher.propTypes = {
  children: PropTypes.node,
}

export default ErrorCatcher
