import { InferType, object, string } from 'yup'

import { MedicationSchema } from 'schemas/MedicalHistory'
import { formatMedicationDateToApi } from 'util/patient'
import { Medication } from 'types/PrivateData/MedicalHistory/Medication'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medicationId: string().required(),
  medication: MedicationSchema.required(),
})

export type Request = InferType<typeof Schema>

export const url = ({ memberId, medicationId }: Request) =>
  `/v2/history/personal/${memberId}/medications/${medicationId}`

export const getBody = (params: Request) => {
  const { direction, strengthAndUnit, startDate, endDate, dispensableDrugId, ...medication } = MedicationSchema.cast(
    params.medication,
    { stripUnknown: true },
  )

  return {
    medication: {
      ...medication,
      direction: direction || null,
      strengthAndUnit: strengthAndUnit || null,
      startDate: formatMedicationDateToApi(startDate) || null,
      endDate: formatMedicationDateToApi(endDate) || null,
      dispensableDrugId: dispensableDrugId || null,
    },
  }
}

export type SuccessfulResponse = {
  medications: Medication[]
}
