import { CancellationReasonType } from 'schemas/CancelVisit'
import { InferType, object, string } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  filter: object()
    .shape({
      visit_type: string().required(),
    })
    .required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = {
  data: CancellationReasonType[]
}

export const url = ({ filter }) => `/v1/cancellation-reasons?${queryString.stringify({ filter }, { indices: false })}`
