import { createMuiTheme as createTheme } from '@material-ui/core'

const getThemeMerger = (getTheme) => {
  let prevTheme = null
  let cachedResult = null

  return (curTheme) => {
    if (curTheme !== prevTheme) {
      prevTheme = curTheme
      cachedResult = createTheme(curTheme, getTheme(curTheme))
    }
    return cachedResult
  }
}

export default getThemeMerger
