import { object, string } from 'yup'
import qs from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  storeName: string(),
  specialty: string(),
  city: string(),
  state: string(),
  zip: string(),
})

export const url = ({ storeName, specialty, city, zip, state }) =>
  `/v1/pharmacies?${qs.stringify({
    filter: {
      store_name: storeName,
      specialty,
      city,
      zip,
      state,
    },
  })}`
