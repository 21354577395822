import { Lambda, makeAutoObservable, observable, onBecomeObserved } from 'mobx'
import makeQuery from 'api/makeQuery.ts'
import { SuccessfulResponse } from 'api/requests/v1/get-visit-escalations'
import PendingVisit from 'models/Provider/visits/PendingVisit'
import { InferType } from 'yup'
import { CrisisEscalationSchema } from 'schemas/CrisisEscalation.ts'

class CrisisEscalation {
  list: SuccessfulResponse = []
  visit: PendingVisit
  disposeEscalationFetcher: Lambda

  constructor(visit: PendingVisit, initialValues?: SuccessfulResponse) {
    if (initialValues) {
      this.list = initialValues
    }

    makeAutoObservable(this, {
      destroy: false,
      list: observable.shallow,
    })
    this.visit = visit
    this.disposeEscalationFetcher = onBecomeObserved(this, 'list', () => {
      this.fetchEscalations()
    })
  }

  get lastEscalation() {
    if (!this.hasEscalated) return null
    return this.list[this.list.length - 1]
  }

  get hasEscalated() {
    return this.list.length > 0
  }

  *fetchEscalations() {
    if ('pendingVisitId' in this.visit) {
      const visitId = this.visit.pendingVisitId as string
      const escalation = yield makeQuery('getVisitEscalations', { visitId })
      this.list = escalation || []
    }
  }

  async addEscalation(escalation: InferType<typeof CrisisEscalationSchema>) {
    if ('pendingVisitId' in this.visit) {
      const visitId = this.visit.pendingVisitId as string
      if (!escalation.notes) delete escalation.notes
      const response = await makeQuery('postVisitEscalation', { visitId, escalation }).catch((err) => {
        console.error(err)
        throw err
      })
      this.list.push(response)
    }
  }

  destroy() {
    this.disposeEscalationFetcher()
    this.list = []
  }
}

export default CrisisEscalation
