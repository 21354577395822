import { object, string } from 'yup'

export const PushRegistrationSchema = object({
  providerId: string().required(),
  registration: object({
    device: object({
      make: string().required(),
      model: string().required(),
      modelVersion: string().required(),
      platform: string().required(),
      appVersion: string().required(),
      timezone: string().required(),
    }).required(),
    token: object({
      type: string().required(),
      data: string().required(),
    }).required(),
  }),
})
