import { makeAutoObservable } from 'mobx'
import debounce from 'lodash/debounce'

import { formatNoteDescriptionToBackend } from 'util/notes'
import makeQuery from 'api/makeQuery'
import MemberExtendedData from 'singletons/MemberExtendedData'

class Notes {
  syncDebounced = debounce(async (note, noteId) => {
    const mergeStamp = ++this.queriesCounter
    let visitRecord
    if (!note && noteId) {
      visitRecord = await makeQuery('deleteVisitNote', {
        visitId: this.visit.visitId,
        noteId,
      })
    } else if (noteId) {
      visitRecord = await makeQuery('putVisitNote', {
        visitId: this.visit.visitId,
        note,
        noteId,
      })
    } else if (note.description) {
      visitRecord = await makeQuery('postVisitNote', {
        visitId: this.visit.visitId,
        note,
      })
    }
    if (visitRecord && mergeStamp === this.queriesCounter) {
      this.visit.merge(visitRecord)
    }
  }, 1000)

  constructor(visit, initialValues) {
    /* Array of: { type, description, id } */
    this.list = initialValues || []

    makeAutoObservable(this, {
      destroy: false,
      syncDebounced: false,
      map: false,
    })

    this.visit = visit

    /* if 1st query finishes after 2nd - old data must not be merged */
    this.queriesCounter = 0
    this.workingType = null
  }

  sync = (type) => {
    // Check if we are working on a new note type, if so flush the old debounce
    if (this.workingType !== type) {
      this.syncDebounced.flush()
    }
    this.workingType = type
    return this.syncDebounced
  }

  get length() {
    return this.list.length
  }

  map(cb) {
    return this.list.map(cb)
  }

  replace(arr) {
    this.list.replace(arr || [])
  }

  destroy() {
    this.syncDebounced.flush()
  }

  /* By backend logics, first of history/exam notes should be displayed and edited on frontend. */

  get history() {
    return this.list.find((note) => note.type === 'history')
  }

  get exam() {
    return this.list.find((note) => note.type === 'exam')
  }

  get psychotherapy() {
    return this.list.find((note) => note.type === 'other')
  }

  /* But this stuff can be added in unlimited quantities */

  get addendum() {
    return this.list.filter((note) => note.type === 'addendum')
  }

  delete(id, type) {
    const noteIndex = this.list.findIndex((note) => note.id === id)
    if (noteIndex !== -1) {
      this.list.splice(noteIndex, 1)
      this.sync(type)(false, id)
    }
  }

  edit(id, description, type) {
    const noteObject = this.list.find((note) => note.id === id)
    if (noteObject) {
      noteObject.description = formatNoteDescriptionToBackend(description)
      this.sync(type)(noteObject, id)
    }
  }

  create(description, type) {
    let noteObject = this.list.find((note) => note.type === type)
    if (noteObject) {
      noteObject.description = formatNoteDescriptionToBackend(description)
    } else {
      const isTypeOther = type === 'other'
      noteObject = {
        type,
        description: formatNoteDescriptionToBackend(description),
        ...(isTypeOther && { createdBy: 'provider' }),
        ...(isTypeOther && { createdById: this.visit.providerId }),
      }
    }
    this.sync(type)(noteObject, '')
  }

  update(id, description, type) {
    if (!description && id) {
      this.delete(id, type)
    } else {
      if (id) {
        this.edit(id, description, type)
      } else {
        this.create(description, type)
      }
    }
  }

  async addAddendum(description, createdFromVisitId = null) {
    const visitRecord = await makeQuery('postVisitNote', {
      visitId: this.visit.visitId,
      note: {
        type: 'addendum',
        description,
        createdFromVisitId,
      },
    })
    this.visit.merge(visitRecord)
    await MemberExtendedData.refetch(visitRecord.memberId)
  }
}

export default Notes
