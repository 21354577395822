import { InferType, object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  pharmacyId: string().required(),
})

export type SuccessfulResponse = any

export type Request = InferType<typeof Schema>

export const url = ({ pharmacyId }) => `/v1/pharmacies/external/${pharmacyId}`
