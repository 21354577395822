import { object, string } from 'yup'
import qs from 'qs'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  query: string().required(),
  visitType: string().required(),
})

export const url = ({ query, visitType }) => {
  const params = () => {
    switch (visitType) {
      case 'hc':
        return {
          query,
        }
      case 'success_coaching':
        return {
          query,
          filter: {
            type: 'success_coaching',
          },
        }
      default:
        return {
          query,
          filter: {
            type: visitType !== 'medical' && visitType !== 'hc' ? 'behavioral' : visitType,
          },
        }
    }
  }
  return `/v1/discharge_instructions/search?${qs.stringify({
    ...params(),
  })}`
}
