const CONTACT_REASONS_MAP = {
  scheduling_change: 'Scheduling Change',
  cancellation: 'Cancellation',
  technology_problem: 'Technology Problem',
  provider_documentation: 'Provider Documentation',
  referral_question: 'Referral Question',
  client_question: 'Client Question',
  member_visit_concern: 'Member Visit Rejections/Concern',
  other_request: 'Other',
}

export const contactReasonsOptionsArray = Object.keys(CONTACT_REASONS_MAP).map((value) => value)

export const contactReasonsOptions = Object.keys(CONTACT_REASONS_MAP).map((value) => ({
  value,
  label: CONTACT_REASONS_MAP[value],
}))
