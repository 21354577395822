/* lodash pick works only with raw Objects */

/* @__PURE__*/
const pick = (obj, array) => {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof obj !== 'object') {
      console.error(obj)
    }
  }
  const result = {}
  if (obj && array) {
    for (const k of array) {
      if (k in obj) {
        result[k] = obj[k]
      }
    }
  }
  return result
}

export default pick
