import { runInAction, observable } from 'mobx'
import throttle from 'lodash/throttle'
import makeQuery from 'api/makeQuery'

class ClientData {
  byClientId = new Map()
  fetchQueue = new Set()

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const clientIds = Array.from(this.fetchQueue)

    for (const clientId of clientIds) {
      const privateData = await makeQuery('getClient', {
        clientId,
      })

      runInAction(() => {
        this.byClientId.get(clientId).set(privateData)
      })

      this.fetchQueue.delete(clientId)
    }

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 100)

  get(clientId) {
    let res = this.byClientId.get(clientId)

    if (!res) {
      res = observable.box()
      this.byClientId.set(clientId, res)
      this.fetchQueue.add(clientId)
      this.processQueue()
    }

    return res.get()
  }
}

export default new ClientData()
