import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  paymentId: string().required(),
})

export const url = ({ providerId, paymentId }) => `/v1/providers/${providerId}/payments/${paymentId}?format=pdf`

export const responseType = 'blob'
