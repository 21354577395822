import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    /* must overflow drawer */
    zIndex: 9999,
  },
}))

export default useStyles
