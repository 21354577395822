import { LinearProgress } from 'ui-components-web'
import { observer } from 'mobx-react-lite'
import PendingQueries from 'singletons/PendingQueries'
import useStyles from './styles'

const LOG_QUANTITY = false

const TopProgressBar = () => {
  const classes = useStyles()
  if (LOG_QUANTITY) {
    console.log('Running queries quantity: ', PendingQueries.length)
  }
  return PendingQueries.empty ? null : <LinearProgress classes={classes} />
}

export default observer(TopProgressBar)
