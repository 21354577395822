import { object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  providerPhone: string()
    .label('Provider Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must be a 10 digit number')
    .required(),
  memberPhone: string()
    .label('Member Phone Number')
    .matches(/^(\d{10})$/, 'Phone number must be a 10 digit number')
    .required(),
})

export const url = ({ visitId }) => `/v1/visits/${visitId}/conferences`

export const getBody = ({ providerPhone, memberPhone }) => ({
  providerPhone,
  memberPhone,
})
