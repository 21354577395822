import { makeObservable, runInAction } from 'mobx'

import mergeMobxListsBy from 'util/mergeMobxListsBy'
import RxChange from './RxChange'

class RxChangesCollection {
  list = []
  map = new Map()

  constructor(provider, getRxChanges) {
    makeObservable(this, {
      list: true,
      map: true,
    })
    this.provider = provider
    this.getRxChanges = getRxChanges
  }

  async refetch() {
    const rxChangesList = await this.getRxChanges()

    runInAction(() => mergeMobxListsBy(this.map, this.list, rxChangesList, 'rxChangeRequestId', (r) => new RxChange(r)))
  }

  // Should check length because fetch onBecomeObserved
  getById(id) {
    return this.list.length ? this.map.get(+id) : null
  }
}

export default RxChangesCollection
