import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerRxVendorId: string().required(),
  patientRxVendorId: string().required(),
  prescriptionId: string().required(),
})

export type SuccessfulResponse = any

export type Request = InferType<typeof Schema>

export const url = ({ providerRxVendorId, patientRxVendorId, prescriptionId }) =>
  `/v1/providers/${providerRxVendorId}/patients/${patientRxVendorId}/prescriptions/${prescriptionId}`
