import { object, string, boolean } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  visitId: string().required(),
  timeframe: string(),
  careCoordinatorNeeded: boolean(),
  serviceType: string(),
})

export const url = ({ visitId }) => `/v1/visits/${visitId}/referral`

export const getBody = ({ notes, timeframe, careCoordinatorNeeded, serviceType }) => ({
  notes,
  timeframe,
  careCoordinatorNeeded: !!careCoordinatorNeeded,
  serviceType,
})
