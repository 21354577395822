import { makeAutoObservable, observable, runInAction } from 'mobx'
import pollAudioLevel from './pollAudioLevel'

class AudioVisualizer {
  lastPolledAudioLevel = 0
  stopPolling: (() => void) | null = null

  constructor() {
    makeAutoObservable(this, {
      lastPolledAudioLevel: observable.ref,
    })
  }

  async start(audioTrack: MediaStreamTrack) {
    if (!this.stopPolling) {
      this.stopPolling = await pollAudioLevel(audioTrack, (level) =>
        runInAction(() => {
          this.lastPolledAudioLevel = level
        }),
      )
    }
  }

  async stop() {
    if (this.stopPolling) {
      this.stopPolling?.()
      this.stopPolling = null
    }
  }
}

export default AudioVisualizer
