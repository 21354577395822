import { object, string, array } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

const QuestionItemSchema = object().shape({
  questionId: string().required('Question ID Required'),
  answers: array(),
})

export const Schema = object().shape({
  visitId: string().required(),
  questionnaireId: string().required(),
  questions: array().of(QuestionItemSchema).required('Question Answers Required'),
})

export const url = ({ visitId }) => `/v1/visit/${visitId}/assessment`

export const getBody = ({ questionnaireId, questions }) => ({
  questionnaireId,
  questions,
})
