import {
  COMMUNITY_EXPERTISE,
  DIAGNOSTIC_EXPERTISE,
  GENDER_IDENTITY,
  LANGUAGES,
  LICENSES,
  PRONOUNS,
  RACE_ETHNICITY,
  RELIGIOUS_EXPERTISE,
} from 'constants/providerProfiles'
import { array, object, string } from 'yup'

const ProfileSchema = object().shape({
  pronouns: string().label('Pronouns').oneOf(PRONOUNS),
  raceEthnicity: string().label('Race & Ethnicity').oneOf(RACE_ETHNICITY),
  genderIdentity: string().label('Gender Identity').oneOf(GENDER_IDENTITY),
  licenses: array().label('License(s)').of(string().oneOf(LICENSES, 'One of Licenses values is not allowed')),
  diagnosticExpertise: array()
    .label('Diagnostic Expertise')
    .of(string().oneOf(DIAGNOSTIC_EXPERTISE, 'One of Diagnostic Expertise values is not allowed')),
  communityExpertise: array()
    .label('Community Expertise')
    .of(string().oneOf(COMMUNITY_EXPERTISE, 'One of Community Expertise values is not allowed')),
  religiousExpertise: array()
    .label('Religious & Spiritual Expertise')
    .of(string().oneOf(RELIGIOUS_EXPERTISE, 'One of Religious & Spiritual Expertise values is not allowed')),
  languages: array()
    .label('Languages')
    .min(1, 'Languages field must have at least 1 item')
    .of(string().oneOf(LANGUAGES, 'One of Language values is not allowed')),
  aboutMe: string().label('About Me'),
  bestPartSuccessCoach: string().label('Best Part of Success Coaching'),
  collegeSuccessTips: string().label('Tips for Success'),
  almaMater: string().label('College / University Attended'),
  firstNamePreferred: string().label('Preferred Name').nullable().notRequired(),
})

export default ProfileSchema
