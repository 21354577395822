import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'
import { AuthChallengeValues } from 'types/AuthChallenge/authChallenge'

export const Schema = object().shape({
  email: string().email().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = {
  authChallenge: AuthChallengeValues
  redirectUrl?: string
}

export const url = () => `/v1/me/auth-challenge`

export const getBody = ({ email }: Request) => ({ email })
