/* TODO: make singleton inheritance pattern */

import { observable } from 'mobx'
import throttle from 'lodash/throttle'
import makeQuery from 'api/makeQuery'

const toStr = (id1, id2) => id1 + '_|_' + id2
const fromStr = (k) => k.split('_|_')

class GroupServices {
  map = new Map()
  fetchQueue = new Set()

  /* TODO: add mass fetching if needed; after that timeout can be increased */
  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return
    }

    const ids = Array.from(this.fetchQueue)

    for (const k of ids) {
      const [clientId, groupId] = fromStr(k)
      await this.refetch(clientId, groupId)
      this.fetchQueue.delete(k)
    }

    if (this.fetchQueue.size) {
      this.processQueue()
    }
  }, 0)

  async refetch(clientId, groupId) {
    const services = await makeQuery('getClientGroupServices', {
      clientId,
      groupId,
    })

    this.map.get(toStr(clientId, groupId)).set(services.services)
  }

  get(clientId, groupId) {
    const k = toStr(clientId, groupId)
    let res = this.map.get(k)

    if (!res) {
      res = observable.box()
      this.map.set(k, res)
      this.fetchQueue.add(k)
      this.processQueue()
    }

    return res.get()
  }

  clear() {
    this.map.clear()
    this.fetchQueue.clear()
    this.processQueue.cancel()
  }
}

export default new GroupServices()
