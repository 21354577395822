import { object, string, array } from 'yup'

import { FamilyConditionSchema } from 'schemas/FamilyHistory'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  familyHistory: object().shape({
    memberId: string().required(),
    conditions: array().of(FamilyConditionSchema).required(),
  }),
})

export const url = () => `/v1/history/family`

export const getBody = ({ familyHistory }) => ({ familyHistory })
