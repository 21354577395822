import { object } from 'yup'
import queryString from 'qs'

export { member as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  filter: object(),
  page: object(),
  sort: object(),
})

/* Returns array of visitRecords(not pending) */
export const url = ({ filter, page, sort }) =>
  `/v1/visits?${queryString.stringify({ filter, page, sort }, { indices: false })}`
