import { object, string } from 'yup'
import queryString from 'qs'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
})

/* Returns array of visitRecords(not pending) */
export const url = ({ providerId }) =>
  `/v2/providers/${providerId}/visits?${queryString.stringify({ page: { limit: 1, skip: 0 } }, { indices: false })}`
