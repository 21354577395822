import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  schedulingId: string().required(),
  time: string().required(),
})

export const url = ({ providerId, schedulingId }) =>
  `/v1/providers/${providerId}/schedulings/${schedulingId}/accept/provider`

export const getBody = ({ time }) => ({ time })
