import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  schedule: object().required(),
})

export const url = ({ providerId }) => `/v1/providers/${providerId}/schedule`

export const getBody = ({ schedule }) => ({
  schedule,
})

export const doNotConvertKeysToSnakeCase = true
