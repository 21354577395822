import { makeObservable } from 'mobx'
import mapValues from 'lodash/mapValues'

import Record from 'models/Record'
import { formatDate } from 'util/formatters'

class RequestedMedication extends Record {
  static INITIAL_FIELDS = {
    brand: false,
    clinicId: 0,
    comment: null,
    compoundId: null,
    dateInactive: null,
    daysSupply: null,
    directions: '',
    dispenseUnitDescription: '',
    dispenseUnitId: 0,
    displayName: '',
    doseForm: '',
    drugClassification: null,
    effective_date: null,
    eligibility_id: 0,
    encounter: null,
    errorIgnored: false,
    formulary: false,
    freeTextType: null,
    genericDrugName: '',
    genericProductName: '',
    isUrgent: false,
    lastFillDate: null,
    dispensableDrugId: '',
    medicationStatus: 0,
    monographPath: '',
    ndc: '',
    noSubstitutions: false,
    nonDoseSpotPrescription_id: null,
    otc: false,
    patientMedicationId: 0,
    pharmacyId: 0,
    pharmacyNotes: '',
    prescriberAgentId: null,
    prescriberId: 0,
    prescriptionId: 0,
    quantity: '',
    refills: '',
    route: '',
    rxCUI: '',
    rxReferenceNumber: null,
    schedule: '',
    stateSchedules: null,
    status: 0,
    strength: '',
    supervisorId: null,
    supplyId: null,
    type: 0,
    writtenDate: '',
  }

  constructor(valuesObj) {
    super(valuesObj)
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    })
  }

  get medicationName() {
    return this.displayName
  }

  get medicationDirections() {
    return this.directions
  }

  get medicationNotes() {
    return this.pharmacyNotes
  }

  get medicationQuantity() {
    return this.quantity
  }

  get medicationRefills() {
    return this.refills
  }

  get medicationDaysSupply() {
    return this.daysSupply || 0
  }

  get medicationUnit() {
    const unitDescription = this.dispenseUnitDescription
    const strength = this.strength
    if (unitDescription && strength) {
      return ` ${strength}${unitDescription}`
    }
    return ''
  }

  get medicationSubstitutions() {
    return this.noSubstitutions ? 'Not Allowed' : 'Allowed'
  }

  get medicationWrittenDate() {
    return formatDate(this.writtenDate)
  }
}

export default RequestedMedication
