export const PRESCRIBER_AUTH_SUB_TYPES_MAP = {
  'State License Status': {
    label: 'Enter State License Number',
    type: 'text',
  },
  'DEA Status': {
    label: 'Enter DEA Number',
    type: 'text',
  },
  'DEA Class': {
    label: 'Enter DEA Class',
    type: 'text',
  },
  'State CSR Status': {
    label: 'Enter State Controlled Substance Registration License',
    type: 'text',
  },
  'State CSR Class': {
    label: 'Enter State Controlled Substance Registration Class',
    type: 'text',
  },
  NADEA: {
    label: 'Enter NADEA Number',
    type: 'text',
  },
  NPI: {
    label: 'Enter NPI Number',
    type: 'text',
  },
  'Prescription Benefit': {
    label: 'Enter Benefit Plan Enroll/Re-Enrolled Date',
    type: 'date',
  },
  'Prescription Authority': {
    label: 'Enter Specialty',
    type: 'text',
  },
  REMS: {
    label: 'Enter REMS Enroll/Re-Enroll Date',
    type: 'date',
  },
  'Lock-in Prescriber': {
    label: 'Enter Prescriber effective date',
    type: 'date',
  },
  // Not supported
  // 'Supervising Prescriber': {
  //   label: 'Select a supervisor from dropdown',
  //   type: 'select',
  // },
  CPT: {
    label: 'Enter Certificate to Prescriber Number',
    tyoe: 'text',
  },
}
