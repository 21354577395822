import { InferType, object, string } from 'yup'
import { TimeSlot } from 'types/Provider/Schedule'
import { TimeSlotPendingSchema } from 'schemas/TimeSlot'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  scheduleId: string().required(),
  slot: TimeSlotPendingSchema,
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = TimeSlot

export const url = ({ providerId, scheduleId }: Request) => `/v2/providers/${providerId}/schedules/${scheduleId}/slots`

export const getBody = ({ slot }) => ({ slot })
