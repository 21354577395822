import { ProviderSchema } from 'schemas/Provider'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = ProviderSchema

export const url = ({ providerId }) => `/v1/providers/${providerId}`

export const getBody = ({ providerId: _, ...values }) => ({
  provider: values,
})
