import { createMuiTheme as createTheme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

import { rgba } from 'polished'
import { STACKING_TABLES_MOBILE_REARRANGEMENT } from 'constants/breakpoints'

const breakpoints = createBreakpoints({})

const theme = createTheme({
  overrides: {
    MuiStepper: {
      root: {
        backgroundColor: 'unset',
      },
    },
    MuiButton: {
      contained: {
        '&.Mui-disabled': {
          backgroundColor: '#C7C9CC',
          color: '#ffffff',
        },
      },
      containedPrimary: {
        color: '#ffffff',
        '&:hover': {
          backgroundColor: '#0C3152',
        },
      },
    },
    MuiInputBase: {
      root: {
        '& .Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.65)', // (default alpha is 0.38)
        },
      },
      input: {},
    },
    MuiFormHelperText: {
      root: {
        '&::first-letter': {
          textTransform: 'capitalize',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#E7E9EC',
      },
    },
    MuiTableBody: {
      root: {
        [breakpoints.up(STACKING_TABLES_MOBILE_REARRANGEMENT)]: {
          '& tr:first-child td': {
            paddingTop: 0,
          },
        },

        '& tr': {
          '& td': {
            [breakpoints.down(STACKING_TABLES_MOBILE_REARRANGEMENT)]: {
              padding: '8px',
            },
          },
          '& td:first-child': {
            [breakpoints.down(STACKING_TABLES_MOBILE_REARRANGEMENT)]: {
              width: 'initial',
              border: 'none',
            },
          },
          '& td:last-child': {
            [breakpoints.down(STACKING_TABLES_MOBILE_REARRANGEMENT)]: {
              width: 'initial',
              paddingBottom: 30,
              marginBottom: 30,
            },
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        [breakpoints.down(STACKING_TABLES_MOBILE_REARRANGEMENT)]: {
          display: 'flex',
          flexDirection: 'column',
          '& h1': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: '#204667',
      dark: '#204566',
      primary200: '#C2D8EC',
      primary700: '#144773',
    },
    avatar: {
      background: rgba('#2fafcc', 0.2),
    },
    secondary: {
      main: '#204566',
    },
    tertiary: {
      main: '#39BBC0',
      disabled: '#C3EAEC',

      lightGreen: '#2FAFCC',
      darkGreen: '#006F84',
      brightGreen: '#0F801C',
      backgroundGrey: '#E7E9EC',
      lightGrey: '#717173',
      ashenGrey: '#6E727A',
      pewterGrey: '#5E6678',
      darkGrey: '#272527',
      lightBlue: '#179FEC',
      darkBlue: '#0C3152',
      orange: '#FEA62A',
      red: '#D10D13',
      // main: `rgba(${hexToRgb('#2fafcc')}, 0.75)`
    },
    quarternary: {
      main: '#175F82',
      disabled: '#a2bfcd',
      // main: `rgba(${hexToRgb('#204566')}, 0.75)`
    },
    text: {
      primary: '#1c1d1f',
      secondary: '#c7c9cC',
      link: '#36c9eb',
      disabled: '#c7c9cC',
    },
    error: {
      main: '#DE5E5E',
      secondary: '#cf0000',
    },
    background: {
      main: '#f9fafc',
    },
    appBar: {
      background: '#ffffff',
    },
    dashboard: {
      card1: '#006F84',
      /* Used in Dashboard/appointment/contentBlock */
      card2: '#E7E9EC',
    },
    warn: {
      main: '#ED9D66',
    },
    white: '#ffffff',
    pill: {
      background: '#D4D8E2',
      text: '#04070D',
    },
    neutral: {
      neutral800: '#04070D',
      neutral600: '#424C61',
    },
  },
  dimens: {
    radius: 12,
    spacing: 8,
    text: {
      normal: 16,
      caption: 12,
      title: 40,
    },
    drawerWidth: 250,
    mobileDrawerWidth: 250,
  },
})

export default theme
