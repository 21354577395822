import { number, string, date, array, bool, object, lazy } from 'yup'

export const BasicPrescription = object().shape({
  patientMedicationId: number().nullable().notRequired(),
  prescriptionId: number().nullable().notRequired(),
  prescriberId: number().nullable().notRequired(),
})

const DAYS_SUPPLY_MIN_MAX_MESSAGE = 'Days Supply must be between 0 and 365'

export const RxDaysSupplySchema = number()
  .typeError('Must be a number')
  .integer('Must be a whole number')
  .min(0, DAYS_SUPPLY_MIN_MAX_MESSAGE)
  .max(365, DAYS_SUPPLY_MIN_MAX_MESSAGE)

const Prescription = object()
  .shape({
    dispensableDrugId: number().notRequired(),
    refills: number().notRequired(),
    dispenseUnitId: number().notRequired(),
    quantity: number().notRequired(),
    directions: string().notRequired(),
    displayName: string().notRequired(),
    ndc: string().notRequired().nullable(),
    // .required("Required"),
    strength: string().notRequired(),
    status: lazy((value) => {
      switch (typeof value) {
        case 'number': {
          return number().oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], 'Invalid Status').notRequired()
        }
        default:
          return string().notRequired()
      }
    }),
    prescriptionId: number().nullable().notRequired(),
    pharmacyId: number().nullable().notRequired(),
    prescriberId: number().nullable().notRequired(),
    prescriberAgentId: number().nullable().notRequired(),
    eligibilityId: number().nullable().notRequired(),
    supplyId: number().nullable().notRequired(),
    compoundId: number().nullable().notRequired(),
    clinicId: number().nullable().notRequired(),
    supervisorId: number().nullable().notRequired(),
    patientMedicationId: number().nullable().notRequired(),
    MedicationStatus: number().nullable().notRequired(),
    daysSupply: RxDaysSupplySchema.notRequired().nullable(),
    type: lazy((value) => {
      switch (typeof value) {
        case 'number': {
          return number().notRequired()
        }
        default:
          return string().notRequired()
      }
    }),
    writtenDate: date().nullable().notRequired(),
    effectiveDate: date().nullable().notRequired(),
    lastFillDate: date().nullable().notRequired(),
    dateInactive: date().nullable().notRequired(),
    dispenseUnitDescription: string().nullable().notRequired(),
    pharmacyNotes: string().nullable().notRequired(),
    rxReferenceNumber: string().nullable().notRequired(),
    freeTextType: string().nullable().notRequired(),
    comment: string().nullable().notRequired(),
    encounter: string().nullable().notRequired(),
    doseForm: string().nullable().notRequired(),
    route: string().nullable().notRequired(),
    genericProductName: string().nullable().notRequired(),
    genericDrugName: string().nullable().notRequired(),
    rxCui: string().nullable().notRequired(),
    schedule: string().nullable().notRequired(),
    monographPath: string().nullable().notRequired(),
    drugClassification: string().nullable().notRequired(),
    nonDoseSpotPrescriptionId: string().nullable().notRequired(),
    noSubstitutions: bool().nullable().notRequired(),
    formulary: bool().nullable().notRequired(),
    errorIgnored: bool().nullable().notRequired(),
    otc: bool().nullable().notRequired(),
    stateSchedules: array().strip(),
  })
  .concat(BasicPrescription)

export default Prescription
