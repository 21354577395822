export const days = {
  m: 'Monday',
  t: 'Tuesday',
  w: 'Wednesday',
  th: 'Thursday',
  f: 'Friday',
  s: 'Saturday',
  su: 'Sunday',
}

export const DAYS_UNTIL_LICENSE_EXPIRE = 30

Object.freeze(days)
