import { object, array } from 'yup'
import queryString from 'qs'

export { member as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  'filter[_id]': array(),
})

export const url = (params) => `/v1/members/list?${queryString.stringify(params)}`

export const getBody = (params) => params['filter[_id]']
