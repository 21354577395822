import { Suspense, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/common/Modal'
import Modals from 'singletons/Modals'
import ModalsMap from '../v1Modals'
import useStyles from './styles'

const ModalWrapper = ({ type, id, options }) => {
  const classes = useStyles()
  const closeHandler = useCallback(() => Modals.close(id), [id])
  const { Component, label, titleCancelButtonVariant, rootClassName } = ModalsMap[type]
  const finalLabel = label ? (typeof label === 'function' ? label(options) : label) : null
  return (
    Component && (
      <Modal
        open
        onClose={closeHandler}
        title={finalLabel}
        titleCancelButtonVariant={titleCancelButtonVariant}
        dialogClasses={{ paper: classes[`${rootClassName}Root`] }}
      >
        <Suspense fallback={`Loading modal: ${type}`}>
          <Component {...options} closeModal={closeHandler} />
        </Suspense>
      </Modal>
    )
  )
}
ModalWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  options: PropTypes.object,
}

export default ModalWrapper
