import { object, string, InferType } from 'yup'
import type { Resource } from 'types/Provider'
import { DosespotNotConfirmedResponse } from 'types/Login/DosespotNotConfirmedResponse'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  mfaCode: string().min(6).max(6).required(),
})

export type Request = InferType<typeof Schema>

type RegularResponse = {
  auth: {
    userId: string
    token: string
    refreshToken: string
    /** Used to bypass regular mfa flow by remembering email-token pair */
    refreshMfaToken: string
    /** date like (2023-07-27T15:27:25.958Z) */
    createdAt: string
    /** date like (2023-07-27T15:27:25.958Z) */
    expiresAt: string
    /** date like (2023-07-27T15:27:25.958Z) */
    refreshMfaExpiresAt: string
  }
  provider: any
  providerId: string
  passwordExpired: boolean
  resources: Resource[]
}

// TODO: remove any when provider gets typed
export type SuccessfulResponse = RegularResponse | DosespotNotConfirmedResponse

export const url = () => `/v2/me/verify-mfa`

export const getBody = ({ mfaCode }: Request) => ({ mfaCode })
