import { object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  providerId: string().required(),
  phoneId: string().required(),
  verificationCode: string().min(6, 'Verification code must be 6 characters long').required(),
})

export const url = ({ providerId, phoneId }) => `/v1/providers/${providerId}/phone/${phoneId}/verify`

export const getBody = ({ verificationCode }) => ({ verificationCode })
